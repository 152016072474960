<template>
  <div class="login-page">
    <div class="login-form">
      <div class="logo">
        <img
          src="@/assets/images/ingest_logo_full.svg"
          alt="Ingest Logo" />
      </div>

      <form @submit.prevent="login">
        <h2>Login</h2>
        <validation-observer ref="form">
          <validation-provider
            v-slot="{ errors }"
            rules="required|email">
            <b-field
              label="Email"
              :message="errors[0]"
              class="mt-5">
              <input
                v-model="email"
                class="input" />
            </b-field>
          </validation-provider>
          <validation-provider
            v-slot="{ errors }"
            rules="required">
            <b-field
              label="Password"
              :message="errors[0]"
              class="mt-5">
              <input
                v-model="password"
                type="password"
                class="input" />
            </b-field>
          </validation-provider>
          <button
            class="button is-black"
            :disabled="isLoading"
            type="submit">
            <span v-if="!isLoading">Login</span>

            <b-loading
              v-model="isLoading"
              :is-full-page="false" />
          </button>
          <div
            v-if="loginError"
            class="login-error">
            Invalid email or password!
          </div>

          <router-link
            :to="{ name: 'password_reset', query: $route.query }"
            class="forgot-passoword">
            Forgot Password?
          </router-link>
        </validation-observer>
      </form>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'LoginPage',
    components: {},
    data () {
      return {
        email: '',
        password: '',
        isLoading: false,
        valid: true,
        loginError: false,
      }
    },
    computed: {},
    mounted () {
    },
    methods: {
      toPasswordReset () {
        this.$router.push({ name: 'password_reset', query: this.$route.query, })
      },
      async login () {
        this.loginError = false
        const isFormValidatied = await this.$refs.form.validate()
        if (!isFormValidatied) return

        this.isLoading = true
        this.valid = await this.$store.dispatch('auth/login', {
          email: this.email,
          password: this.password,
        })
        this.isLoading = false
        if (this.valid) {
          this.$nextTick(() => {
            this.$router.replace(
              this.$route.query.redirect ||
                this.$store.getters['auth/user/defaultRoute']
            )
          })
        } else {
          this.loginError = true
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
.login-page {
  display: flex;
  width: 100%;
  min-height: 100vh;
  background-color: #f7f3ef;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(40deg, $grayscale-9, $grayscale-7, $primary);
  background-size: 400% 400%;
  animation: gradient 20s ease infinite;
  padding: 20px;

  @keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  .login-form {
    width: 100%;
    max-width: 500px;
    background-color: white;
    padding: 30px 20px;
    border-radius: 10px;

    @include mobile {
      padding: 50px 40px;
    }

    .logo {
      display: flex;
      justify-content: center;
      img {
        width: 120px;

        @include mobile {
          width: 150px;
        }
      }
    }

    form {
      margin-top: 30px;

      @include mobile {
        margin-top: 40px;
      }

      .input {
        height: 40px;
        transition: all 0.3s;

        @include mobile {
          height: 48px;
        }

        &:focus {
          box-shadow: none;
          border-color: black;
        }
      }

      button {
        width: 100%;
        height: 40px;
        margin-top: 20px;

        @include mobile {
          height: 48px;
        }
      }

      h2 {
        font-weight: 500;
        font-size: 20px;
        margin-bottom: 20px;

        @include mobile {
          font-size: 22px;
          margin-bottom: 30px;
        }
      }

      .login-error {
        color: $warning;
        text-align: center;
        margin-top: 10px;
        font-size: 14px;
      }

      .forgot-passoword {
        color: $grayscale-1;
        text-align: center;
        margin-top: 20px;
        font-size: 14px;
        display: block;

        @include mobile {
          font-size: 15px;
        }

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
</style>
