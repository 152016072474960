<template>
  <div class="time-range-filter">
    <b-dropdown
      ref="calendar-dropdown"
      aria-role="menu"
      min-height="auto"
      :disabled="disabled"
      trap-focus>
      <template #trigger="{ active }">
        <a
          class="datepicker-button"
          :class="{ 'no-border': noBorder }"
          role="button">
          <b-icon
            v-if="!hideIcon"
            size="is-small"
            class="search-icon icon"
            icon="calendar-search" />
          <div class="datepicker-button-label">
            <div
              v-if="!hideSecondaryLabel && datePickerLabelData.type"
              class="date-type">
              {{ datePickerLabelData.typeLabel }}
            </div>

            <div class="date-range">
              <div v-if="datePickerLabelData.mode === 'DATES'">
                {{ datePickerLabelData.noOfDatesSelected }}
                {{
                  datePickerLabelData.noOfDatesSelected > 1
                    ? "days selected"
                    : "day selected"
                }}
              </div>
              <div v-else-if="datePickerLabelData.rangeText">
                {{ datePickerLabelData.rangeText }}
              </div>
              <div v-else>Select Date Range</div>
            </div>
          </div>
          <b-icon
            class="icon"
            :icon="active ? 'menu-up' : 'menu-down'" />
        </a>
      </template>

      <b-dropdown-item
        aria-role="menu-item"
        :focusable="false"
        custom
        paddingless>
        <form @submit.prevent="submitHandler">
          <div class="modal-card">
            <section class="modal-card-body">
              <b-tabs
                v-model="modeTabs"
                type="is-toggle"
                animate-initially
                expanded>
                <b-tab-item
                  class="tab-item"
                  value="calendar"
                  label="Calendar">
                  <div>
                    <b-field label="Select Date Range">
                      <multiselect
                        v-model="calendarPresetInput"
                        :options="calendarPresets"
                        :allow-empty="false"
                        deselect-label=""
                        label="label"
                        @input="calendarPresetHandler" />
                    </b-field>
                  </div>

                  <div class="include-today-select">
                    <b-field v-if="showCalendarIncludeToday">
                      <b-checkbox
                        v-model="calendarIncludeToday"
                        @input="onCalendarIncludeTodayInput">
                        <span class="pl-1"> Include Today </span>
                      </b-checkbox>
                    </b-field>
                  </div>

                  <div
                    v-if="
                      calendarPresetInput &&
                        calendarPresetInput.key === 'custom_range'
                    "
                    class="datepicker">
                    <b-field>
                      <b-datepicker
                        v-model="calendarCustomRangeInput"
                        :day-names="[
                          'Su',
                          'Mo',
                          'Tu',
                          'We',
                          'Th',
                          'Fr',
                          'Sa',
                        ]"
                        inline
                        expanded
                        placeholder="Click to select..."
                        range
                        @input="onCalendarCustomDateRangeInput" />
                    </b-field>
                    <div
                      v-if="calendarCustomRangeInput.length === 2"
                      class="date-range-container">
                      <div class="custom-date-box">
                        {{ customDateRangeFormatted[0] }}
                      </div>
                      <div>
                        <b-icon
                          size="is-small"
                          class="arrow-right"
                          icon="arrow-right" />
                      </div>

                      <div class="custom-date-box">
                        {{ customDateRangeFormatted[1] }}
                      </div>
                    </div>
                  </div>

                  <div
                    v-else-if="
                      calendarPresetInput &&
                        calendarPresetInput.key === 'custom_dates'
                    "
                    class="datepicker">
                    <b-field>
                      <b-datepicker
                        v-model="calendarCustomDatesInput"
                        :day-names="[
                          'Su',
                          'Mo',
                          'Tu',
                          'We',
                          'Th',
                          'Fr',
                          'Sa',
                        ]"
                        inline
                        expanded
                        multiple
                        @input="onCalendarCustomDatesInput" />
                    </b-field>

                    <div class="date-range-container">
                      <div class="custom-date-box">
                        {{ calendarCustomDatesInput.length }} Dates Selected
                      </div>
                    </div>
                  </div>

                  <div
                    v-else-if="calendarPresetInput"
                    class="date-range-container">
                    <div class="custom-date-box">
                      {{ calendarDateStartFormatted }}
                    </div>
                    <div>
                      <b-icon
                        size="is-small"
                        class="arrow-right"
                        icon="arrow-right" />
                    </div>

                    <div class="custom-date-box">
                      {{ calendarDateEndFormatted }}
                    </div>
                  </div>
                  <div
                    v-if="isDateRangeExceedingLimit"
                    class="limit-exceeded-message">
                    Selected date range cannot exceed 750 individual dates
                  </div>
                </b-tab-item>

                <b-tab-item
                  value="fiscal_calendar"
                  label="Fiscal Calendar">
                  <div>
                    <b-field label="Select a Preset">
                      <multiselect
                        v-model="fiscalPresetInput"
                        :options="fiscalPresetOptions"
                        label="label"
                        track-by="key"
                        @input="fiscalPresetHandler" />
                    </b-field>
                  </div>
                  <div class="include-today-select">
                    <b-field v-if="showFiscalIncludeToday">
                      <b-checkbox
                        v-model="fiscalIncludeToday"
                        @input="onFiscalCalendarIncludeTodayInput">
                        <span class="pl-1"> Include Today </span>
                      </b-checkbox>
                    </b-field>
                  </div>

                  <div class="accuracy-mode-switch">
                    <b-field class="accuracy-field">
                      <b-switch
                        v-model="accuracyMode"
                        @input="accuracyModeChanged" />
                      <span> Use specific fiscal calendar options </span>
                    </b-field>
                  </div>

                  <div class="input-container">
                    <div class="quick-actions">
                      <b-button
                        class="quick-b-button"
                        type="is-text"
                        @click="fiscalYearInput = uniqueFiscalYearsAvailable">
                        Select All
                      </b-button><b-button
                        class="quick-b-button"
                        type="is-text"
                        @click="fiscalYearInput = []">
                        Deselect All
                      </b-button>
                    </div>
                    <b-field label="Year">
                      <multiselect
                        ref="fiscal_year_input"
                        v-model="fiscalYearInput"
                        :options="uniqueFiscalYearsAvailable"
                        :hide-selected="true"
                        :close-on-select="false"
                        :clear-on-select="false"
                        placeholder="All Years"
                        label="year"
                        track-by="year_key"
                        multiple
                        open-direction="below"
                        @open="scrollTo('fiscal_year_input')"
                        @input="onFiscalYearInput" />
                    </b-field>
                  </div>

                  <div class="input-container">
                    <div class="quick-actions">
                      <b-button
                        class="quick-b-button"
                        type="is-text"
                        @click="
                          fiscalQuarterInput = uniqueFiscalQuartersAvailable
                        ">
                        Select All
                      </b-button><b-button
                        class="quick-b-button"
                        type="is-text"
                        @click="fiscalQuarterInput = []">
                        Deselect All
                      </b-button>
                    </div>
                    <b-field label="Quarter">
                      <multiselect
                        ref="fiscal_quarter_input"
                        v-model="fiscalQuarterInput"
                        :options="uniqueFiscalQuartersAvailable"
                        :hide-selected="true"
                        :close-on-select="false"
                        :clear-on-select="false"
                        placeholder="All Quarters"
                        label="quarter"
                        track-by="filter_quarter_key"
                        multiple
                        open-direction="below"
                        @open="scrollTo('fiscal_quarter_input')"
                        @input="onFiscalQuarterInput" />
                    </b-field>
                  </div>

                  <div class="input-container">
                    <div class="quick-actions">
                      <b-button
                        class="quick-b-button"
                        type="is-text"
                        @click="
                          fiscalPeriodInput = uniqueFiscalPeriodsAvailable
                        ">
                        Select All
                      </b-button><b-button
                        class="quick-b-button"
                        type="is-text"
                        @click="fiscalPeriodInput = []">
                        Deselect All
                      </b-button>
                    </div>
                    <b-field label="Period">
                      <multiselect
                        ref="fiscal_period_input"
                        v-model="fiscalPeriodInput"
                        :options="uniqueFiscalPeriodsAvailable"
                        :hide-selected="true"
                        :close-on-select="false"
                        :clear-on-select="false"
                        placeholder="All Periods"
                        label="period"
                        track-by="filter_period_key"
                        multiple
                        open-direction="below"
                        @open="scrollTo('fiscal_period_input')"
                        @input="onFiscalPeriodInput" />
                    </b-field>
                  </div>

                  <div class="input-container">
                    <div class="quick-actions">
                      <b-button
                        class="quick-b-button"
                        type="is-text"
                        @click="fiscalWeekInput = uniqueFiscalWeeksAvailable">
                        Select All
                      </b-button><b-button
                        class="quick-b-button"
                        type="is-text"
                        @click="fiscalWeekInput = []">
                        Deselect All
                      </b-button>
                    </div>
                    <b-field label="Week">
                      <multiselect
                        ref="fiscal_week_input"
                        v-model="fiscalWeekInput"
                        :options="uniqueFiscalWeeksAvailable"
                        :hide-selected="true"
                        :close-on-select="false"
                        :clear-on-select="false"
                        placeholder="All Weeks"
                        label="week"
                        track-by="filter_week_key"
                        multiple
                        open-direction="below"
                        @open="scrollTo('fiscal_week_input')"
                        @input="onFiscalWeekInput" />
                    </b-field>
                  </div>
                  <div class="input-container">
                    <div class="quick-actions">
                      <b-button
                        class="quick-b-button"
                        type="is-text"
                        @click="fiscalDayInput = uniqueFiscalDaysAvailable">
                        Select All
                      </b-button><b-button
                        class="quick-b-button"
                        type="is-text"
                        @click="fiscalDayInput = []">
                        Deselect All
                      </b-button>
                    </div>
                    <b-field label="Day">
                      <multiselect
                        ref="fiscal_day_input"
                        v-model="fiscalDayInput"
                        :options="uniqueFiscalDaysAvailable"
                        :hide-selected="true"
                        :close-on-select="false"
                        :clear-on-select="false"
                        placeholder="All Days"
                        label="day"
                        track-by="filter_day_key"
                        multiple
                        open-direction="below"
                        @open="scrollTo('fiscal_day_input')"
                        @input="onFiscalDayInput" />
                    </b-field>
                  </div>
                  <div class="date-range-container">
                    <div class="custom-date-box">
                      {{ fiscalDateStartFormatted }}
                    </div>
                    <div>
                      <b-icon
                        size="is-small"
                        class="arrow-right"
                        icon="arrow-right" />
                    </div>

                    <div class="custom-date-box">
                      {{ fiscalDateEndFormatted }}
                    </div>
                  </div>
                  <div
                    v-if="!areDatesContinuous"
                    class="dates-excluded-message">
                    Some dates excluded
                  </div>
                  <div
                    v-if="isDateRangeExceedingLimit"
                    class="limit-exceeded-message">
                    Selected date range cannot exceed 750 individual dates
                  </div>
                </b-tab-item>
              </b-tabs>
            </section>

            <footer class="modal-card-foot">
              <b-button
                class="b-buttons left"
                expanded
                :disabled="defaultBtnDisabled"
                @click="onDefaultButton">
                Default
              </b-button>

              <b-button
                class="b-buttons"
                expanded
                :disabled="cancelBtnDisabled"
                @click="onCancelButton">
                Cancel
              </b-button>
              <b-tooltip
                :active="isDateRangeExceedingLimit"
                position="is-right"
                type="is-warning"
                multilined
                append-to-body>
                <template
                  #content>
                  Selected date range cannot exceed 750 individual dates
                </template>
                <b-button
                  expanded
                  type="is-primary b-buttons"
                  :disabled="applyBtnDisabled"
                  native-type="submit">
                  Apply
                </b-button>
              </b-tooltip>
            </footer>
          </div>
        </form>
      </b-dropdown-item>
    </b-dropdown>
  </div>
</template>

<script>
  import { minBy, maxBy, uniqBy } from 'lodash'

  import * as moment from 'moment'
  import { formatDate, formatDateRange } from '@/utils/Date.js'
  export default {
    name: 'TimeRangeFilter',
    props: {
      default: {
        type: Object,
        default: () => ({}),
      },
      hideIcon: {
        type: Boolean,
        default: false,
      },
      hideSecondaryLabel: {
        type: Boolean,
        default: false,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      noBorder: {
        type: Boolean,
        default: false,
      },
    },

    data () {
      return {
        datePickerLabelData: {
          type: '',
          start: '',
          end: '',
          noOfDatesSelected: 0,
        },
        dayOrder: [
          'Monday',
          'Tuesday',
          'Wednesday',
          'Thursday',
          'Friday',
          'Saturday',
          'Sunday',
        ],
        modeTabs: 'calendar',
        accuracyMode: false,

        isCalendarInputsDirty: false,
        isFiscalCalendarInputsDirty: false,

        calendarPresetInput: undefined,
        calendarCustomRangeInput: [],
        calendarCustomDatesInput: [],

        fiscalIncludeToday: false,
        calendarIncludeToday: false,

        fiscalPresetInput: undefined,
        fiscalYearInput: [],
        fiscalQuarterInput: [],
        fiscalPeriodInput: [],
        fiscalWeekInput: [],
        fiscalDayInput: [],

        currentAppliedData: null,

        fiscalPresetOptions: [
          {
            label: 'Today',
            key: 'today',
          },
          {
            label: 'Yesterday',
            key: 'yesterday',
          },
          {
            label: 'Week to date',
            key: 'wtd',
            showIncludeToday: true,
          },
          {
            label: 'Previous week',
            key: 'prev_week',
          },
          {
            label: 'Period to date',
            key: 'ptd',
            showIncludeToday: true,
          },
          {
            label: 'Previous period',
            key: 'prev_period',
          },
          {
            label: 'Trailing 12 Periods',
            key: 'trailing_12_periods',
            showIncludeToday: false,
          },
          {
            label: 'Quarter to date',
            key: 'qtd',
            showIncludeToday: true,
          },
          {
            label: 'Previous quarter',
            key: 'prev_quarter',
          },
          {
            label: 'Year to date',
            key: 'ytd',
            showIncludeToday: true,
          },
          {
            label: 'Previous year',
            key: 'prev_year',
          },
          // {
          //   label: 'Start to date',
          //   key: 'std',
          //   showIncludeToday: true,
          // },
        ],
      }
    },
    computed: {
      showCalendarIncludeToday () {
        return (
          this.calendarPresetInput &&
          this.calendarPresetInput.showIncludeToday &&
          (this.calendarPresetInput.dates.startFormatted !==
            this.calendarPresetInput.dates.endFormatted ||
            this.calendarIncludeToday === false)
        )
      },
      showFiscalIncludeToday () {
        return (
          this.fiscalPresetInput &&
          this.fiscalPresetInput.showIncludeToday &&
          (this.fiscalDateStartFormatted !== this.fiscalDateEndFormatted ||
            this.fiscalIncludeToday === false)
        )
      },
      isCalendarIncludeTodayDisabled () {
        return true
      },
      areDatesContinuous () {
        if (this.fiscalOutputDates.length) {
          const startDate = moment(this.fiscalDateStart)
          const endDate = moment(this.fiscalDateEnd)
          return (
            this.fiscalOutputDates.length === endDate.diff(startDate, 'days') + 1
          )
        }
        return true
      },

      isDateRangeExceedingLimit () {
        // limit is 750 individual dates

        if (this.modeTabs === 'calendar') {

          if (this.calendarPresetInput?.key === 'custom_dates') {
            //if custom dates use the length of the array
            return this.calendarCustomRangeInput.length > 750
          }

          let startDate, endDate
          if  (this.calendarPresetInput?.key === 'custom_range')  {
            //if custom range use the start and end date
            if (this.calendarCustomRangeInput.length === 2) {
              startDate = this.calendarCustomRangeInput[0]
              endDate = this.calendarCustomRangeInput[1]
            }
            else {
              //if custom range is not 2 days, return false
              return false
            }
          }

          else {
            //if preset then use the start and end date of preset
            startDate = this.calendarDateStart
            endDate = this.calendarDateEnd
          }

          const daysDiff = moment(endDate)?.diff(moment(startDate), 'days') + 1
          return daysDiff > 750
        }

        else if (this.fiscalOutputDates.length > 750) {
          return true
        }
        return false
      },

      cancelBtnDisabled () {
        if (this.isCalendarInputsDirty || this.isFiscalCalendarInputsDirty) {
          return false
        }
        return true
      },

      defaultBtnDisabled () {
        return this.currentAppliedData === null
      },

      applyBtnDisabled () {
        if (this.isDateRangeExceedingLimit) {
          return true
        }

        if (this.modeTabs === 'calendar') {
          if (this.calendarPresetInput) {
            if (
              //check if a custom date rage is selected
              (this.calendarPresetInput.key === 'custom_range' &&
                this.calendarCustomRangeInput.length !== 2) ||
              //check if  custom dates are selected
              (this.calendarPresetInput.key === 'custom_dates' &&
                this.calendarCustomDatesInput.length < 1)
            ) {
              return true
            }
          }
          return !this.isCalendarInputsDirty
        } else {
          return !this.isFiscalCalendarInputsDirty
        }
      },

      fiscalDateStart () {
        if (this.fiscalOutputDates.length) {
          return this.fiscalOutputDates[0]
        }
        return ''
      },
      fiscalDateStartFormatted () {
        return this.getDateFormatted(this.fiscalDateStart)
      },

      fiscalDateEnd () {
        if (this.fiscalOutputDates.length) {
          return this.fiscalOutputDates.slice(-1)[0]
        }
        return ''
      },
      fiscalDateEndFormatted () {
        return this.getDateFormatted(this.fiscalDateEnd)
      },

      calendarDateStart () {
        if (
          this.calendarPresetInput.shiftRangeOnExcludeToday &&
          this.showCalendarIncludeToday &&
          !this.calendarIncludeToday
        ) {
          return moment(this.calendarPresetInput.dates.start)
            .subtract(1, 'days')
            .format('YYYY-MM-DD')
        }
        return this.calendarPresetInput.dates.start
      },
      calendarDateStartFormatted () {
        if (
          this.calendarPresetInput.shiftRangeOnExcludeToday &&
          this.showCalendarIncludeToday &&
          !this.calendarIncludeToday
        ) {
          return moment(this.calendarPresetInput.dates.start)
            .subtract(1, 'days')
            .format('ll')
        }
        return this.calendarPresetInput.dates.startFormatted
      },

      calendarDateEnd () {
        if (this.showCalendarIncludeToday && !this.calendarIncludeToday) {
          return this.yesterdayDate
        }
        return this.calendarPresetInput.dates.end
      },
      calendarDateEndFormatted () {
        if (this.showCalendarIncludeToday && !this.calendarIncludeToday) {
          return this.yesterdayDateFormatted
        }
        return this.calendarPresetInput.dates.endFormatted
      },

      minimumDate () {
        return minBy(this.availableDates, (d) => d.date)
      },

      maximumDate () {
        return maxBy(this.availableDates, (d) => d.date)
      },
      availableDates () {
        return this.$store.getters['datePicker/availableDates'] || []
      },
      firstDayOfWeek () {
        const pythonfirstDayOfWeek =
          this.$store.getters['metadata/companyMetadata'][
            'start_of_fiscal_week'
          ] || 0
        // python monday is 0, Js monday is 1
        return Math.abs(pythonfirstDayOfWeek + 1) % 7
      },
      fiscalAvailableDates () {
        if (
          !this.fiscalIncludeToday &&
          this.fiscalPresetInput &&
          this.fiscalPresetInput.showIncludeToday &&
          this.availableDates.slice(-1)[0].date_key === this.todayDate
        ) {
          return this.availableDates.slice(0, -1)
        }
        return this.availableDates
      },
      todayDate () {
        return this.getDateFormattedId()
      },
      yesterdayDate () {
        return this.getDateFormattedId(moment().subtract(1, 'days'))
      },
      yesterdayDateFormatted () {
        return this.getDateFormatted(moment().subtract(1, 'days'))
      },
      calendarPresets () {
        moment.updateLocale('en', {
          week: {
            dow: this.firstDayOfWeek,
          },
        })
        return [
          {
            label: 'Custom Range',
            key: 'custom_range',
          },
          {
            label: 'Custom Dates',
            key: 'custom_dates',
          },
          {
            label: 'Today',
            key: 'today',
            dates: {
              start: this.todayDate,
              startFormatted: this.getDateFormatted(),
              end: this.todayDate,
              endFormatted: this.getDateFormatted(),
            },
          },
          {
            label: 'Yesterday',
            key: 'yesterday',
            dates: {
              start: this.yesterdayDate,
              startFormatted: this.getDateFormatted(this.yesterdayDate),
              end: this.yesterdayDate,
              endFormatted: this.getDateFormatted(this.yesterdayDate),
            },
          },
          {
            label: 'Week to Date',
            key: 'week_to_date',
            showIncludeToday: true,
            dates: {
              start: moment().startOf('week').format('YYYY-MM-DD'),
              startFormatted: moment().startOf('week').format('ll'),
              end: this.getDateFormattedId(),
              endFormatted: this.getDateFormatted(),
            },
          },

          {
            label: 'Current Week',
            key: 'current_week',
            showIncludeToday: false,
            dates: {
              start: moment().startOf('week').format('YYYY-MM-DD'),
              startFormatted: moment().startOf('week').format('ll'),
              end: moment().endOf('week').format('YYYY-MM-DD'),
              endFormatted: moment().endOf('week').format('ll'),
            },
          },
          {
            label: 'Previous Week',
            key: 'previous_week',
            dates: {
              start: moment()
                .subtract(1, 'weeks')
                .startOf('week')
                .format('YYYY-MM-DD'),
              startFormatted: moment()
                .subtract(1, 'weeks')
                .startOf('week')
                .format('ll'),
              end: moment()
                .subtract(1, 'weeks')
                .endOf('week')
                .format('YYYY-MM-DD'),
              endFormatted: moment()
                .subtract(1, 'weeks')
                .endOf('week')
                .format('ll'),
            },
          },
          {
            label: 'Month to Date',
            key: 'month_to_date',
            showIncludeToday: true,
            dates: {
              start: moment().startOf('month').format('YYYY-MM-DD'),
              startFormatted: moment().startOf('month').format('ll'),
              end: this.getDateFormattedId(),
              endFormatted: this.getDateFormatted(),
            },
          },
          {
            label: 'Current Month',
            key: 'current_month',
            showIncludeToday: false,
            dates: {
              start: moment().startOf('month').format('YYYY-MM-DD'),
              startFormatted: moment().startOf('month').format('ll'),
              end: moment().endOf('month').format('YYYY-MM-DD'),
              endFormatted: moment().endOf('month').format('ll'),
            },
          },
          {
            label: 'Previous Month',
            key: 'previous_month',
            dates: {
              start: moment()
                .subtract(1, 'months')
                .startOf('month')
                .format('YYYY-MM-DD'),
              startFormatted: moment()
                .subtract(1, 'months')
                .startOf('month')
                .format('ll'),
              end: moment()
                .subtract(1, 'months')
                .endOf('month')
                .format('YYYY-MM-DD'),
              endFormatted: moment()
                .subtract(1, 'months')
                .endOf('month')
                .format('ll'),
            },
          },
          {
            label: 'Year to Date',
            key: 'year_to_date',
            showIncludeToday: true,
            dates: {
              start: moment().startOf('year').format('YYYY-MM-DD'),
              startFormatted: moment().startOf('year').format('ll'),
              end: this.getDateFormattedId(),
              endFormatted: this.getDateFormatted(),
            },
          },
          {
            label: 'Current Year',
            key: 'current_year',
            showIncludeToday: false,
            dates: {
              start: moment().startOf('year').format('YYYY-MM-DD'),
              startFormatted: moment().startOf('year').format('ll'),
              end: moment().endOf('year').format('YYYY-MM-DD'),
              endFormatted: moment().endOf('year').format('ll'),
            },
          },
          {
            label: 'Previous Year',
            key: 'previous_year',
            dates: {
              start: moment()
                .subtract(1, 'years')
                .startOf('year')
                .format('YYYY-MM-DD'),
              startFormatted: moment()
                .subtract(1, 'years')
                .startOf('year')
                .format('ll'),
              end: moment()
                .subtract(1, 'years')
                .endOf('year')
                .format('YYYY-MM-DD'),
              endFormatted: moment()
                .subtract(1, 'years')
                .endOf('year')
                .format('ll'),
            },
          },
          {
            label: 'Last 7 Days',
            showIncludeToday: true,
            shiftRangeOnExcludeToday: true,
            key: 'last_7',
            dates: {
              start: moment().subtract(6, 'days').format('YYYY-MM-DD'),
              startFormatted: moment().subtract(6, 'days').format('ll'),
              end: moment().format('YYYY-MM-DD'),
              endFormatted: moment().format('ll'),
            },
          },
          {
            label: 'Last 14 Days',
            showIncludeToday: true,
            shiftRangeOnExcludeToday: true,
            key: 'last_14',
            dates: {
              start: moment().subtract(13, 'days').format('YYYY-MM-DD'),
              startFormatted: moment().subtract(13, 'days').format('ll'),
              end: moment().format('YYYY-MM-DD'),
              endFormatted: moment().format('ll'),
            },
          },
          {
            label: 'Last 28 Days',
            showIncludeToday: true,
            shiftRangeOnExcludeToday: true,
            key: 'last_28',
            dates: {
              start: moment().subtract(27, 'days').format('YYYY-MM-DD'),
              startFormatted: moment().subtract(27, 'days').format('ll'),
              end: moment().format('YYYY-MM-DD'),
              endFormatted: moment().format('ll'),
            },
          },
          {
            label: 'Last 30 Days',
            key: 'last_30',
            showIncludeToday: true,
            shiftRangeOnExcludeToday: true,
            dates: {
              start: moment().subtract(29, 'days').format('YYYY-MM-DD'),
              startFormatted: moment().subtract(29, 'days').format('ll'),
              end: moment().format('YYYY-MM-DD'),
              endFormatted: moment().format('ll'),
            },
          },
          {
            label: 'Last 5 Weeks',
            key: 'last_35',
            showIncludeToday: true,
            shiftRangeOnExcludeToday: true,
            dates: {
              start: moment().subtract(34, 'days').format('YYYY-MM-DD'),
              startFormatted: moment().subtract(34, 'days').format('ll'),
              end: moment().format('YYYY-MM-DD'),
              endFormatted: moment().format('ll'),
            },
          },
          {
            label: 'Last 12 Weeks',
            key: 'last_84',
            showIncludeToday: true,
            shiftRangeOnExcludeToday: true,
            dates: {
              start: moment().subtract(83, 'days').format('YYYY-MM-DD'),
              startFormatted: moment().subtract(83, 'days').format('ll'),
              end: moment().format('YYYY-MM-DD'),
              endFormatted: moment().format('ll'),
            },
          },
          {
            label: 'Last 90 Days',
            key: 'last_90',
            showIncludeToday: true,
            shiftRangeOnExcludeToday: true,
            dates: {
              start: moment().subtract(89, 'days').format('YYYY-MM-DD'),
              startFormatted: moment().subtract(89, 'days').format('ll'),
              end: moment().format('YYYY-MM-DD'),
              endFormatted: moment().format('ll'),
            },
          },
          {
            label: 'Last 364 Days',
            key: 'last_364',
            showIncludeToday: true,
            shiftRangeOnExcludeToday: true,
            dates: {
              start: moment().subtract(363, 'days').format('YYYY-MM-DD'),
              startFormatted: moment().subtract(363, 'days').format('ll'),
              end: moment().format('YYYY-MM-DD'),
              endFormatted: moment().format('ll'),
            },
          },
          // {
          //   label: 'All Time',
          //   key: 'all_time',
          //   showIncludeToday: true,
          //   dates: {
          //     start: this.minimumDate.date_key,
          //     startFormatted: this.getDateFormatted(
          //       new Date(this.minimumDate.date_key)
          //     ),
          //     end: this.maximumDate.date_key,
          //     endFormatted: this.getDateFormatted(
          //       new Date(this.maximumDate.date_key)
          //     ),
          //   },
          // },
        ]
      },

      customDateRangeFormatted () {
        if (
          this.calendarCustomRangeInput &&
          this.calendarCustomRangeInput.length === 2
        ) {
          const start = this.getDateFormatted(this.calendarCustomRangeInput[0])
          const end = this.getDateFormatted(this.calendarCustomRangeInput[1])
          return [start, end,]
        }
        return []
      },

      fiscalYearsAvailable () {
        return this.fiscalAvailableDates || []
      },

      fiscalQuartersAvailable () {
        let options = []
        if (this.fiscalYearInput.length) {
          const filterKeys = this.fiscalYearInput.map((d) => d.year_key)
          options = this.fiscalYearsAvailable.filter((d) =>
            filterKeys.includes(d.year_key)
          )
        } else {
          options = this.fiscalAvailableDates
        }

        if (this.accuracyMode) {
          options = options.map((d) => {
            return {
              ...d,
              quarter: `FY ${d.year} ${d.quarter}`,
              filter_quarter_key: `${d.quarter_key}_${d.year_key}`,
            }
          })
        } else {
          options = options.map((d) => {
            return {
              ...d,
              filter_quarter_key: `${d.quarter_key}`,
            }
          })
        }

        return options || []
      },

      fiscalPeriodsAvailable () {
        let options = []
        if (this.fiscalQuarterInput.length) {
          const filterKeys = this.fiscalQuarterInput.map(
            (d) => d.filter_quarter_key
          )
          options = this.fiscalQuartersAvailable.filter((d) =>
            filterKeys.includes(d.filter_quarter_key)
          )
        } else if (this.fiscalYearInput.length) {
          options = this.fiscalQuartersAvailable
        } else {
          options = this.fiscalAvailableDates
        }
        if (this.accuracyMode) {
          options = options.map((d) => {
            return {
              ...d,
              period: `FY ${d.year} ${d.period}`,
              filter_period_key: `${d.period_key}_${d.quarter_key}_${d.year_key}`,
            }
          })
        } else {
          options = options.map((d) => {
            return {
              ...d,
              filter_period_key: `${d.period_key}`,
            }
          })
        }
        return options || []
      },

      fiscalWeeksAvailable () {
        let options = []
        if (this.fiscalPeriodInput.length) {
          const filterKeys = this.fiscalPeriodInput.map(
            (d) => d.filter_period_key
          )
          options = this.fiscalPeriodsAvailable.filter((d) =>
            filterKeys.includes(d.filter_period_key)
          )
        } else if (this.fiscalQuarterInput.length) {
          options = this.fiscalPeriodsAvailable
        } else if (this.fiscalYearInput.length) {
          options = this.fiscalQuartersAvailable
        } else {
          options = this.fiscalAvailableDates
        }

        if (this.accuracyMode) {
          options = options.map((d) => {
            return {
              ...d,
              week: `FY ${d.year} ${d.week}`,
              filter_week_key: `${d.week_key}_${d.period_key}_${d.quarter_key}_${d.year_key}`,
            }
          })
        } else {
          options = options.map((d) => {
            return {
              ...d,
              filter_week_key: `${d.week_key}`,
            }
          })
        }

        return options || []
      },

      fiscalDaysAvailable () {
        let options = []
        if (this.fiscalWeekInput.length) {
          const filterKeys = this.fiscalWeekInput.map((d) => d.filter_week_key)
          options = this.fiscalWeeksAvailable.filter((d) =>
            filterKeys.includes(d.filter_week_key)
          )
        } else if (this.fiscalPeriodInput.length) {
          options = this.fiscalWeeksAvailable
        } else if (this.fiscalQuarterInput.length) {
          options = this.fiscalPeriodsAvailable
        } else if (this.fiscalYearInput.length) {
          options = this.fiscalQuartersAvailable
        } else {
          options = this.fiscalAvailableDates
        }

        if (this.accuracyMode) {
          options = options.map((d) => {
            return {
              ...d,
              day: `${d.day} (${this.getDateFormatted(d.date)})`,
              filter_day_key: `${d.date_key}`,
            }
          })
        } else {
          options = options.map((d) => {
            return {
              ...d,
              filter_day_key: `${d.day_key}`,
            }
          })
        }
        return options || []
      },

      fiscalOutputDates () {
        if (this.fiscalDayInput.length) {
          if (this.accuracyMode) {
            return this.fiscalDayInput.map((date) => date.date)
          } else {
            const filterKeys = this.fiscalDayInput.map((d) => d.day_key)
            return (
              this.fiscalDaysAvailable
                .filter((d) => filterKeys.includes(d.day_key))
                .map((date) => date.date) || []
            )
          }
        } else {
          return this.fiscalDaysAvailable.map((date) => date.date) || []
        }
      },

      uniqueFiscalYearsAvailable () {
        return uniqBy(this.fiscalAvailableDates, (option) => option.year_key)
      },
      uniqueFiscalQuartersAvailable () {
        const values = uniqBy(
          this.fiscalQuartersAvailable,
          (option) => option.filter_quarter_key
        )
        if (!this.accuracyMode) {
          values.sort(this.quarterSortfn)
        }
        return values
      },
      uniqueFiscalPeriodsAvailable () {
        const values = uniqBy(
          this.fiscalPeriodsAvailable,
          (option) => option.filter_period_key
        )
        if (!this.accuracyMode) {
          values.sort(this.periodSortfn)
        }
        return values
      },
      uniqueFiscalWeeksAvailable () {
        const values = uniqBy(
          this.fiscalWeeksAvailable,
          (option) => option.filter_week_key
        ).sort(this.weekSortfn)
        if (!this.accuracyMode) {
          values.sort(this.weekSortfn)
        }
        return values
      },
      uniqueFiscalDaysAvailable () {
        const values = uniqBy(
          this.fiscalDaysAvailable,
          (option) => option.filter_day_key
        )
        if (!this.accuracyMode) {
          values.sort(this.daySortfn)
        }
        return values
      },
    },
    mounted () {
      this.setDefaultValues()
    },
    methods: {
      async scrollTo (ref) {
        await this.$nextTick()
        this.$refs[ref].$el.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
        })
      },
      setDefaultValues () {
        if (this.default.calendarPreset) {
          this.calendarPresetInput = this.calendarPresets.find(
            (preset) => preset.key === this.default.calendarPreset
          )
          this.modeTabs = 'calendar'
          if (this.default.type && this.default.type !== 'calendar') {
            this.isCalendarInputsDirty = true
          }
        }
        if (this.default.fiscalPreset) {
          this.fiscalPresetInput = this.fiscalPresetOptions.find(
            (preset) => preset.key === this.default.fiscalPreset
          )
          this.fiscalPresetHandler(this.fiscalPresetInput)
          this.modeTabs = 'fiscal_calendar'
          if (this.default.type && this.default.type !== 'fiscal_calendar') {
            this.isFiscalCalendarInputsDirty = true
          }
        }
        if (this.default.type) {
          this.modeTabs = this.default.type
        }
        if (this.default.calendarPreset || this.default.fiscalPreset) {
          this.outputData()
        }
      },

      setLastAppliedValues () {
        if (!this.currentAppliedData) {
          return
        }
        this.modeTabs = this.currentAppliedData.calendarMode

        if (this.currentAppliedData.calendarMode === 'calendar') {
          //apply saved valued to calendar inputs
          this.calendarPresetInput = this.currentAppliedData.calendarPresetInput
          this.calendarCustomDatesInput =
            this.currentAppliedData.calendarCustomDatesInput || []
          this.calendarCustomRangeInput =
            this.currentAppliedData.calendarCustomRangeInput || []
        } else {
          //apply saved values to fiscal calendar inputs
          this.fiscalPresetInput = this.currentAppliedData.fiscalPresetInput
          this.accuracyMode = this.currentAppliedData.accuracyMode
          this.fiscalYearInput = this.currentAppliedData.fiscalYearInput || []
          this.fiscalQuarterInput =
            this.currentAppliedData.fiscalQuarterInput || []
          this.fiscalPeriodInput =
            this.currentAppliedData.fiscalPeriodInput || []
          this.fiscalWeekInput = this.currentAppliedData.fiscalWeekInput || []
          this.fiscalDayInput = this.currentAppliedData.fiscalDayInput || []
        }
      },

      dateSortfn (a, b) {
        if (a.date_key < b.date_key) return -1
        return 1
      },
      yearSortfn (a, b) {
        if (a.year_key < b.year_key) return -1
        return 1
      },
      quarterSortfn (a, b) {
        if (a.quarter_key < b.quarter_key) return -1
        return 1
      },
      periodSortfn (a, b) {
        if (a.period_key < b.period_key) return -1
        return 1
      },
      weekSortfn (a, b) {
        if (a.week_key < b.week_key) return -1
        return 1
      },
      daySortfn (a, b) {
        if (this.dayOrder.indexOf(a.day_key) < this.dayOrder.indexOf(b.day_key))
          return -1
        return 1
      },

      onCalendarCustomDateRangeInput () {
        this.isCalendarInputsDirty = true
      },
      onCalendarCustomDatesInput () {
        this.isCalendarInputsDirty = true
      },

      onCalendarIncludeTodayInput () {
        this.isCalendarInputsDirty = true
      },
      onFiscalCalendarIncludeTodayInput () {
        this.isFiscalCalendarInputsDirty = true
      },

      fiscalInputsEdited () {
        this.isFiscalCalendarInputsDirty = true
        this.fiscalPresetInput = undefined
      },
      onFiscalYearInput () {
        this.fiscalYearInput.sort(this.yearSortfn)
        this.fiscalInputsEdited()
        this.validateFiscalQuarterInput()
      },
      onFiscalQuarterInput () {
        if (this.accuracyMode) {
          this.fiscalQuarterInput.sort(this.dateSortfn)
        } else {
          this.fiscalQuarterInput.sort(this.quarterSortfn)
        }
        this.fiscalInputsEdited()
        this.validateFiscalPeriodInput()
      },
      onFiscalPeriodInput () {
        if (this.accuracyMode) {
          this.fiscalPeriodInput.sort(this.dateSortfn)
        } else {
          this.fiscalPeriodInput.sort(this.periodSortfn)
        }
        this.fiscalInputsEdited()
        this.validateFiscalWeekInput()
      },
      onFiscalWeekInput () {
        if (this.accuracyMode) {
          this.fiscalWeekInput.sort(this.dateSortfn)
        } else {
          this.fiscalWeekInput.sort(this.weekSortfn)
        }
        this.fiscalInputsEdited()
        this.validateFiscalDayInput()
      },
      onFiscalDayInput () {
        if (this.accuracyMode) {
          this.fiscalDayInput.sort(this.dateSortfn)
        } else {
          this.fiscalDayInput.sort(this.daySortfn)
        }
        this.fiscalInputsEdited()
      },

      validateFiscalQuarterInput () {
        if (this.fiscalQuarterInput.length) {
          const filterKeys = this.fiscalYearInput.map((d) => d.year_key)
          const filteredInputValue = this.fiscalQuarterInput.filter((d) =>
            filterKeys.includes(d.year_key)
          )
          if (filteredInputValue.length < this.fiscalQuarterInput.length) {
            //found a invalid value
            this.fiscalQuarterInput = filteredInputValue
            this.validateFiscalPeriodInput()
          }
        }
      },

      validateFiscalPeriodInput () {
        if (this.fiscalPeriodInput.length) {
          const filterKeys = this.fiscalQuarterInput.map((d) => d.quarter_key)
          const filteredInputValue = this.fiscalPeriodInput.filter((d) =>
            filterKeys.includes(d.quarter_key)
          )
          if (filteredInputValue.length < this.fiscalPeriodInput.length) {
            //found a invalid value
            this.fiscalPeriodInput = filteredInputValue
            this.validateFiscalWeekInput()
          }
        }
      },

      validateFiscalWeekInput () {
        if (this.fiscalWeekInput.length) {
          const filterKeys = this.fiscalPeriodInput.map((d) => d.period_key)
          const filteredInputValue = this.fiscalWeekInput.filter((d) =>
            filterKeys.includes(d.period_key)
          )
          if (filteredInputValue.length < this.fiscalWeekInput.length) {
            //found a invalid value
            this.fiscalWeekInput = filteredInputValue
            this.validateFiscalDayInput()
          }
        }
      },
      validateFiscalDayInput () {
        if (this.fiscalDayInput.length) {
          const filterKeys = this.fiscalWeekInput.map((d) => d.week_key)
          const filteredInputValue = this.fiscalDayInput.filter((d) =>
            filterKeys.includes(d.week_key)
          )
          if (filteredInputValue.length < this.fiscalDayInput.length) {
            //found a invalid value
            this.fiscalDayInput = filteredInputValue
          }
        }
      },

      async accuracyModeChanged () {
        await this.$nextTick()

        if (this.fiscalQuarterInput.length) {
          const filterKeys = this.fiscalQuarterInput.map((d) => d.quarter_key)
          const combinedValues = this.fiscalQuartersAvailable.filter((d) =>
            filterKeys.includes(d.quarter_key)
          )
          this.fiscalQuarterInput = uniqBy(
            combinedValues,
            (option) => option.filter_quarter_key
          )
          this.onFiscalQuarterInput()
        }

        if (this.fiscalPeriodInput.length) {
          const filterKeys = this.fiscalPeriodInput.map((d) => d.period_key)
          const combinedValues = this.fiscalPeriodsAvailable.filter((d) =>
            filterKeys.includes(d.period_key)
          )
          this.fiscalPeriodInput = uniqBy(
            combinedValues,
            (option) => option.filter_period_key
          )
          this.onFiscalPeriodInput()
        }

        if (this.fiscalWeekInput.length) {
          const filterKeys = this.fiscalWeekInput.map((d) => d.week_key)
          const combinedValues = this.fiscalWeeksAvailable.filter((d) =>
            filterKeys.includes(d.week_key)
          )
          this.fiscalWeekInput = uniqBy(
            combinedValues,
            (option) => option.filter_week_key
          )
          this.onFiscalWeekInput()
        }

        if (this.fiscalDayInput.length) {
          const filterKeys = this.fiscalDayInput.map((d) => d.day_key)
          const combinedValues = this.fiscalDaysAvailable.filter((d) =>
            filterKeys.includes(d.day_key)
          )
          this.fiscalDayInput = uniqBy(
            combinedValues,
            (option) => option.filter_day_key
          )
          this.onFiscalDayInput()
        }
      },
      getDateFormatted (d = new Date()) {
        return formatDate(d)
      },
      getDateFormattedId (d = new Date()) {
        return formatDate(d, 'YYYY-MM-DD')
      },

      clearFiscalInputs () {
        this.fiscalYearInput = []
        this.fiscalQuarterInput = []
        this.fiscalPeriodInput = []
        this.fiscalWeekInput = []
        this.fiscalDayInput = []
      },

      clearAllFiscalInputs () {
        this.fiscalPresetInput = undefined
        this.fiscalYearInput = []
        this.fiscalQuarterInput = []
        this.fiscalPeriodInput = []
        this.fiscalWeekInput = []
        this.fiscalDayInput = []
      },

      clearAllCalendarInputs () {
        this.calendarPresetInput = undefined
        this.calendarCustomDatesInput = []
        this.calendarCustomRangeInput = []
      },

      resetAllInputs () {
        this.clearAllFiscalInputs()
        this.clearAllCalendarInputs()

        this.makeInputsClean()
      },

      calendarPresetHandler () {
        this.isCalendarInputsDirty = true
      },

      fiscalPresetHandler (preset) {
        this.isFiscalCalendarInputsDirty = true
        this.clearFiscalInputs()

        if (!this.fiscalAvailableDates.length) {
          return
        }
        if (preset.key === 'trailing_12_periods') {
          this.accuracyMode = true
          const period = this.uniqueFiscalPeriodsAvailable.slice(-13, -1)
          this.fiscalPeriodInput = period
        }

        if (
          [
            'ytd',
            'qtd',
            'ptd',
            'wtd',
            'today',
            'prev_week',
            'prev_period',
            'prev_quarter',
          ].includes(preset.key)
        ) {
          const year = this.fiscalYearsAvailable.slice(-1)
          this.fiscalYearInput = year
        }

        if (
          ['qtd', 'ptd', 'wtd', 'today', 'prev_week', 'prev_period',].includes(
            preset.key
          )
        ) {
          const quarter = this.fiscalQuartersAvailable.slice(-1)
          this.fiscalQuarterInput = quarter
        }

        if (['ptd', 'wtd', 'today', 'prev_week',].includes(preset.key)) {
          const period = this.fiscalPeriodsAvailable.slice(-1)
          this.fiscalPeriodInput = period
        }

        if (['wtd', 'today',].includes(preset.key)) {
          const week = this.fiscalWeeksAvailable.slice(-1)
          this.fiscalWeekInput = week
        }

        if (preset.key === 'std') {
        //do nothing -> no selection = all selection
        }
        if (preset.key === 'today') {
          const day = this.fiscalDaysAvailable.slice(-1)
          this.fiscalDayInput = day
        }
        if (preset.key === 'yesterday') {
          this.accuracyMode = true
          const day = this.fiscalDaysAvailable.slice(-2, -1)
          this.fiscalDayInput = day
        }
        if (preset.key === 'prev_week') {
          if (this.uniqueFiscalWeeksAvailable.length < 2) {
            //this means that the prev week is in the previous period and we need to swith to prev period and fetch a last week an so on
            if (this.uniqueFiscalPeriodsAvailable.length < 2) {
              if (this.uniqueFiscalQuartersAvailable.length < 2) {
                const year = this.uniqueFiscalYearsAvailable.slice(-2, -1)
                this.fiscalYearInput = year
                const quarter = this.uniqueFiscalQuartersAvailable.slice(-1)
                this.fiscalQuarterInput = quarter
              } else {
                const quarter = this.uniqueFiscalQuartersAvailable.slice(-2, -1)
                this.fiscalQuarterInput = quarter
              }
              const period = this.uniqueFiscalPeriodsAvailable.slice(-1)
              this.fiscalPeriodInput = period
            } else {
              const period = this.uniqueFiscalPeriodsAvailable.slice(-2, -1)
              this.fiscalPeriodInput = period
            }
            const week = this.uniqueFiscalWeeksAvailable.slice(-1)
            this.fiscalWeekInput = week
          } else {
            const week = this.uniqueFiscalWeeksAvailable.slice(-2, -1)
            this.fiscalWeekInput = week
          }
        }
        if (preset.key === 'prev_period') {
          if (this.uniqueFiscalPeriodsAvailable.length < 2) {
            //this means that the prev period is in the previous quarter and we need to swith to prev quarter and fetch a last period and so on
            if (this.uniqueFiscalQuartersAvailable.length < 2) {
              const year = this.uniqueFiscalYearsAvailable.slice(-2, -1)
              this.fiscalYearInput = year
              const quarter = this.uniqueFiscalQuartersAvailable.slice(-1)
              this.fiscalQuarterInput = quarter
            } else {
              const quarter = this.uniqueFiscalQuartersAvailable.slice(-2, -1)
              this.fiscalQuarterInput = quarter
            }
            const period = this.uniqueFiscalPeriodsAvailable.slice(-1)
            this.fiscalPeriodInput = period
          } else {
            const period = this.uniqueFiscalPeriodsAvailable.slice(-2, -1)
            this.fiscalPeriodInput = period
          }
        }
        if (preset.key === 'prev_quarter') {
          if (this.uniqueFiscalQuartersAvailable.length < 2) {
            //this means that the prev quarter is in the previous year and we need to swith to prev year and fetch a last quarter
            const year = this.uniqueFiscalYearsAvailable.slice(-2, -1)
            this.fiscalYearInput = year
            const quarter = this.uniqueFiscalQuartersAvailable.slice(-1)
            this.fiscalQuarterInput = quarter
          } else {
            const quarter = this.uniqueFiscalQuartersAvailable.slice(-2, -1)
            this.fiscalQuarterInput = quarter
          }
        }
        if (preset.key === 'prev_year') {
          const year = this.uniqueFiscalYearsAvailable.slice(-2, -1)
          this.fiscalYearInput = year
        }
      },
      updateCurrentAppliedData () {
        this.currentAppliedData = {
          calendarMode: this.modeTabs,
        }
        if (this.modeTabs == 'calendar') {
          this.currentAppliedData.calendarPresetInput = this.calendarPresetInput
          if (this.calendarPresetInput.key === 'custom_range') {
            this.currentAppliedData.calendarCustomRangeInput =
              this.calendarCustomRangeInput
          } else if (this.calendarPresetInput.key === 'custom_dates') {
            this.currentAppliedData.calendarCustomDatesInput =
              this.calendarCustomDatesInput
          }
        } else {
          this.currentAppliedData.fiscalPresetInput = this.fiscalPresetInput
          this.currentAppliedData.accuracyMode = this.accuracyMode
          this.currentAppliedData.fiscalYearInput = this.fiscalYearInput
          this.currentAppliedData.fiscalQuarterInput = this.fiscalQuarterInput
          this.currentAppliedData.fiscalPeriodInput = this.fiscalPeriodInput
          this.currentAppliedData.fiscalWeekInput = this.fiscalWeekInput
          this.currentAppliedData.fiscalDayInput = this.fiscalDayInput
        }
      },
      outputData () {
        if (this.modeTabs == 'calendar') {
          const calendarSelectionData = {
            mode: 'DATES', // can be RANGE or DATES
            dates: [],
          }
          if (this.calendarPresetInput.key === 'custom_range') {
            if (this.calendarCustomRangeInput.length !== 2) {
              this.$buefy.toast.open({
                message: 'Please select a date range',
                position: 'is-bottom',
                type: 'is-danger',
              })
              return
            }
            calendarSelectionData.mode = 'RANGE'
            calendarSelectionData.dates = this.calendarCustomRangeInput.map(
              (date) => this.getDateFormattedId(date)
            )
          } else if (this.calendarPresetInput.key === 'custom_dates') {
            if (this.calendarCustomDatesInput.length === 0) {
              this.$buefy.toast.open({
                message: 'Please select custom dates to apply',
                position: 'is-bottom',
                type: 'is-danger',
              })
              return
            }
            calendarSelectionData.mode = 'DATES'
            calendarSelectionData.dates = this.calendarCustomDatesInput.map(
              (date) => this.getDateFormattedId(date)
            )
          } else {
            // Case where preset selected
            calendarSelectionData.mode = 'RANGE'
            calendarSelectionData.dates[0] = this.calendarDateStart
            calendarSelectionData.dates[1] = this.calendarDateEnd
          }

          const startDate = calendarSelectionData.dates[0]
          const endDate = calendarSelectionData.dates.slice(-1)[0]

          this.datePickerLabelData = {
            type: 'calendar',
            typeLabel: this.calendarPresetInput.label,
            mode: calendarSelectionData.mode,
            rangeText: formatDateRange(startDate, endDate, 'll'),
            noOfDatesSelected: calendarSelectionData.dates.length,
          }

          this.isCalendarInputsDirty = false

          calendarSelectionData.label = this.datePickerLabelData
          this.$emit('submit', calendarSelectionData)
          this.clearAllFiscalInputs()
        } else {
          const fiscalSelectionData = {
            mode: 'DATES',
            dates: this.fiscalOutputDates,
          }

          const fiscalCalendarPresetLabel = this.fiscalPresetInput
            ? `${this.fiscalPresetInput.label}`
            : 'Custom'
          const startDate = fiscalSelectionData.dates[0]
          const endDate = fiscalSelectionData.dates.slice(-1)[0]

          this.datePickerLabelData = {
            type: 'fiscal_calendar',
            typeLabel: `Fiscal Calendar - ${fiscalCalendarPresetLabel}`,
            mode: 'RANGE',
            rangeText: formatDateRange(startDate, endDate, 'll'),
          }
          this.isFiscalCalendarInputsDirty = false
          fiscalSelectionData.label = this.datePickerLabelData
          this.$emit('submit', fiscalSelectionData)
          this.clearAllCalendarInputs()
        }
      },

      makeInputsClean () {
        this.isFiscalCalendarInputsDirty = false
        this.isCalendarInputsDirty = false
      },

      submitHandler () {
        this.outputData()
        this.updateCurrentAppliedData()
        this.$refs['calendar-dropdown'].toggle()
      },

      onDefaultButton () {
        this.resetAllInputs()
        this.setDefaultValues()
        this.currentAppliedData = null
        this.$refs['calendar-dropdown'].toggle()
      },
      onCancelButton () {
        this.resetAllInputs()
        if (this.currentAppliedData) {
          this.setLastAppliedValues()
        } else {
          this.setDefaultValues()
        }
        this.$refs['calendar-dropdown'].toggle()
      },
    },
  }
</script>

<style lang="scss" scoped>
.modal-card {
  width: 365px !important;

  @include mobile {
    width: 100% !important;
    padding: 0;
    margin: 0 !important;
  }
  padding: 0;
  .modal-card-body {
    max-height: 500px;
    overflow-y: auto;
    padding: 16px 16px 18px 16px;
  }
  .modal-card-foot {
    background: $grayscale-8;
    display: flex;

    padding: 16px;

    .b-buttons {
      margin-left: 6px;
      height: 38px;
      padding: 12px 16px;
      width: auto;
      font-size: 14px;
      &.left {
        margin-right: auto;
        margin-left: 0;
      }
    }
  }
}

.tab-item {
  margin-top: 20px;
}
.input-container {
  position: relative;
  margin-bottom: 20px;
  .quick-actions {
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    .quick-b-button {
      margin-left: 5px;
      font-size: 10px;
      padding: 0 6px;
      height: 20px;
      color: $grayscale-3;
    }
  }
}

.include-today-select {
  margin-top: 20px;
  margin-bottom: 16px;
}
.accuracy-mode-switch {
  border-top: $border-1;
  border-bottom: $border-1;
  padding: 16px 0;
  font-size: 13px;
  color: #7e7d84;
  margin-bottom: 24px;

  .accuracy-field {
    display: flex;
    align-items: center;

    span {
      margin-left: 6px;
    }
  }
}

.datepicker {
  margin-top: 24px;
}
.datepicker-button {
  background: $grayscale-9;
  width: 100%;
  border: 1px solid $gray-1;
  display: flex;
  align-items: center;
  padding: 8px 10px 8px 14px;
  border-radius: 4px;

  &.no-border {
    border: none !important;
  }

  .icon {
    color: $gray-3;
  }
  .search-icon {
    font-size: 22px !important;
    margin-right: 14px;
    margin-top: -3px;
  }
  .datepicker-button-label {
    margin-right: auto;
    .to {
      margin: 0 5px;
    }
    .date-type {
      font-size: 10px;
      color: $gray-4;
      text-transform: capitalize;
      margin-bottom: 2px;
    }
    .date-range {
      font-size: 14px;
      color: $grayscale-2;
      width: 200px;
    }
  }
  &:hover {
    box-shadow: $box-shadow-1;
  }
}
.date-range-container {
  padding-top: 18px;
  border-top: $border-1;
  display: flex;
  justify-content: center;
  align-items: center;
  .custom-date-box {
    font-size: 14px;
    border: $border-1;
    border-radius: 4px;
    height: 38px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .arrow-right {
    font-size: 20px;
    margin: 3px 15px;
    color: $grayscale-4;
  }
}

.dates-excluded-message {
  font-size: 12px;
  color: $ui-warning;
  margin-top: 5px;
}

.limit-exceeded-message {
  font-size: 12px;
  color: #ff0000;
  margin-top: 5px;
}
</style>
<style lang="scss">
.time-range-filter {
  .dropdown {
    display: flex;
    width: 100%;
  }
}
.filter-header {
    font-size: 14px;
    line-height: 14px;
    letter-spacing: -0.28px;
    border-bottom: 1px solid #d2d2d4;
    display: flex;
    background-color: #fbfbfb;

    .filter-label {
      padding: 8px 16px;
      color: #504f54;
      font-size: 14px;
    }
    .selection {
      padding: 8px 16px;
      color: #504f54;
      font-size: 14px;
      border-left: 1px solid #d2d2d4;
    }
  }
</style>
