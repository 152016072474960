<template>
  <div class="dashboard-navigator">
    <div class="container">
      <b-loading
        v-model="isLoading"
        :is-full-page="false">
        <!-- <lottie-player
          src="https://lottie.host/c82b688b-d3d2-463b-8ef7-16cb0b169ba3/FwKwjZGZTa.json"
          background="transparent"
          speed="1"
          style="width: 70px"
          autoplay
          loop /> -->
      </b-loading>

      <template v-if="!isLoading">
        <div class="control-pane">
          <div v-if="isDashboardEdit">
            <input v-model="controlsJson" />
          </div>
          <!-- <dimension-select-accordian :controls="controls" /> -->
        </div>

        <dashboard
          v-if="$hasPermission('VIEW_DASHBOARDS') && !!dashboardData"
          :dashboard.sync="dashboardData"
          :is-edit.sync="isDashboardEdit" />
      </template>
    </div>
  </div>
</template>

<script>
  import Dashboard from '@/components/dashboard/Dashboard'

  export default {
    name: 'DashboardNavigator',
    components: {
      Dashboard,
    },
    data () {
      return {
        isDashboardEdit: false,
        isLoading: true,
        dashboardData: [],
        controls: [],
      }
    },
    computed: {
      controlsJson: {
        get () {
          return JSON.stringify(this.controls)
        },
        set (controlsJson) {
          this.controls = JSON.parse(controlsJson)
        },
      },
    },
    async mounted () {
      this.loadDashboard()
    },
    methods: {
      async loadDashboard () {
        this.isLoading = true

        const reportId = this.$route.params.dashboard_id
        let reportData = await this.$apis.reports.getReport(reportId)
        this.dashboardData = reportData

        document.title = `${reportData.name} - Ingest`
        this.controls = this.dashboardData.controls
        this.isLoading = false
      },
    },
  }
</script>

<style lang="scss" scoped>

.container {
  min-height: 100%
}
.dashboard-navigator {
  display: flex;
  background-color: $grayscale-8;
  // flex-grow: 1;
  // position: relative;
  // height: 100%;
}

.dashboards-button {
  font-size: 1.6rem;
  font-weight: 700;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 11 * $remGrid;
  background: $secondary-06;
  color: $secondary-01;

  &:hover {
    background: $secondary-07;
  }

  &.isOpen {
    background: $secondary-02;
    color: $secondary-06;
  }

  p {
    text-align: left;
    margin: 0;
    margin-left: 4 * $gridBase;
    flex-grow: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.dashboards {
  overflow-y: auto;
  width: 256px;
  height: 100%;
  padding: 4 * $gridBase;
  overflow-x: hidden;
  overflow-y: auto;
  text-overflow: ellipsis;
  background: $secondary-01;
  max-height: 80vh;
  border-bottom-left-radius: 2 * $gridBase;
  border-bottom-right-radius: 2 * $gridBase;
  box-shadow: 0 0 3px $secondary-07;
}
.delete {
  margin-right: 2 * $gridBase;
}

.ingest-dropdown {
  width: 100%;
}

.name {
  flex-grow: 1;
  font-weight: $emp;
}

.material-icons {
  margin-right: 4 * $gridBase;
}

.search {
  width: 4 * $remGrid;
  height: 4 * $remGrid;
}

.left-nav {
  background: $secondary-01;
}

.dashboards-accordian {
  flex-grow: 1;
  width: 100%;
}

.control-pane {
  background: $ui-07;
}
</style>
