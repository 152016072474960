<template>
  <div class="table-module">
    <div class="table-section">
      <div class="table-header">
        {{ dashboardModule.name || "Untitled" }}
      </div>
      <b-skeleton
        v-if="isLoading"
        height="400px"
        style="border-radius: 0"
        width="100%"
        :active="isLoading" />
      <div
        v-else
        class="table-container">
        <table-column
          v-for="(column, i) in data.columns"
          :key="column.id"
          :class="{ 'sticky-column': i === 0 }"
          :current-depth="0"
          :column="column" />
      </div>
    </div>
  </div>
</template>

<script>
  import TableColumn from './TableColumn.vue'
  import axios from 'axios'
  export default {
    name: 'TableModule',
    components: {
      TableColumn,
    },

    provide () {
      return {
        getMaxDepth: () => this.maxDepth,
        getRows: () => this.rows,
      }
    },
    props: {
      dashboardModule: {
        type: Object,
        required: true,
      },
      dashboardDataContext: {
        type: Object,
        required: true,
      },
      dashboardKey: {
        type: String,
        required: true,
      },
    },
    data () {
      return {
        data: {
          type: '',
          id: '',
          key: '',

          name: '',
          rows: [],
          columns: [],
          depth: 2,
        },
        isLoading: false,
        currentRequest: null,
      }
    },
    computed: {
      maxDepth () {
        return this.data.depth
      },
      rows () {
        return this.data.rows
      },
    },
    watch: {
      dashboardDataContext: {
        handler () {
          this.loadData()
        },
        deep: true,
      },
    },
    mounted () {
      // this.loadData()
    },
    beforeDestroy () {
      if (this.cancelTokenSource) {
        this.cancelTokenSource.cancel('Component destroyed')
      }
    },
    methods: {
      getRowCell (row, column) {
        return this.data.rows[row][column]
      },
      async loadData () {
        try {
          if (this.currentRequest) {
            this.currentRequest.cancel('New request initiated')
          }

          this.currentRequest = axios.CancelToken.source()
          this.isLoading = true

          const response = await this.$apis.companyConfigs.getDashboardModuleData(
            this.dashboardKey,
            this.dashboardModule.key,
            this.dashboardDataContext,
            { cancelToken: this.currentRequest.token, }
          )
          if (response?.error) {
            if (response?.code !=='ERR_CANCELED') {
              this.$buefy.toast.open({
                message: 'Failed to load Chart Data',
                type: 'is-warning',
              })
            }
            return
          }
          this.data = response
          this.isLoading = false

        } catch (err) {
          console.error('Error loading data:', err)
        }
      },
    },
  }
</script>

<style scoped>
.table-container {
  display: flex;
  border: 1px solid #e9e9ea;
  overflow-x: auto;
  font-size: 12px;
  display: relative;
}

.table-header {
  font-size: 16px;
  color: #333;
  padding: 8px;
}

.sticky-column {
  position: sticky;
  left: 0;
  z-index: 1;
  background-color: #fff;
}
.table-module {
  /* margin: 0 -18px; */
}
/* .table-section {
  display: flex;
}
.table-column {
  min-width: 120px;
  border-right: 1px solid #e9e9ea;
}
.header {
  background-color: #f2f2f2;
  padding: 8px;
  font-weight: bold;
  text-align: center;
  border-bottom: 1px solid #e9e9ea;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.cell {
  padding: 8px;
  text-align: center;
  border-bottom: 1px solid #e9e9ea;
}
.location-column {
  background-color: #f8f8f8;
  font-weight: bold;
}
.increase {
  color: #008c84;
  background-color: #f2f9f9;
}
.decrease {
  color: #ff0000;
  background-color: #f9f2f2;
}
.arrow-icon {
  font-size: 14px;
  margin-left: 4px;
} */
</style>
