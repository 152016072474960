<template>
  <canvas
    ref="lineChart"
    class="line-chart-container" />
</template>

<script>
  import { Chart, registerables } from 'chart.js'
  Chart.register(...registerables)

  export default {
    name: 'LineChart',
    props: {
      data: {
        type: Array,
        required: true,
      },
      xAxisLabels: {
        type: Array,
        required: true,
      },
      yAxisDataType: {
        type: String,
        required: true,
      },
    },
    data () {
      return {
        chartInstance: null,
      }
    },
    computed: {
      chartData () {
        const datasets = this.data.map(line => ({
          label: line.label,
          data: line.data,
          borderColor: line.color,
          backgroundColor: line.color + '33', // Adding 33 for 20% opacity
          tension: 0,
          formatted_data: line.formatted_data, // Add formatted_data to dataset
        }))
        return { labels: this.xAxisLabels, datasets, }
      },
    },
    watch: {
      data: {
        handler () {
          this.updateChart()
        },
        deep: true,
      },
      xAxisLabels: {
        handler () {
          this.updateChart()
        },
      },
    },
    mounted () {
      this.createChart()
    },
    beforeUnmount () {
      if (this.chartInstance) {
        this.chartInstance.destroy()
      }
    },
    methods: {
      createChart () {
        const ctx = this.$refs.lineChart.getContext('2d')
        this.chartInstance = new Chart(ctx, {
          type: 'line',
          data: this.chartData,
          options: {
            responsive: true,
            maintainAspectRatio: false,
            scales: {
              x: {
                ticks: {
                  autoSkip: true,
                  maxTicksLimit: 10,
                },
                grid: {
                  display: false,
                },
              },
              y: {
                beginAtZero: true,
                ticks: {
                  callback: (value) => {
                    if (this.yAxisDataType === 'money') {
                      return '$' + (value / 100).toLocaleString()
                    }
                    if (this.yAxisDataType === 'percentage') {
                      return (value * 100).toFixed(2) + '%'
                    }
                    return value
                  },
                },
                grid: {
                  drawBorder: false,
                },
              },
            },
            elements: {
              line: {
                tension: 0,
              },
            },
            plugins: {
              legend: {
                display: false,
              },
              tooltip: {
                mode: 'index',
                intersect: false,
                position: 'nearest',
                yAlign: 'center',
                xAlign: 'right',
                callbacks: {
                  title: function (tooltipItems) {
                    return tooltipItems[0].label
                  },
                  label: function (context) {
                    let label = context.dataset.label || ''
                    if (label) {
                      label += ': '
                    }
                    if (context.parsed.y !== null) {
                      label += context.dataset.formatted_data[context.dataIndex]
                    }
                    return label
                  },
                },
              },
            },
          },
        })
      },
      updateChart () {
        if (this.chartInstance) {
          this.chartInstance.data = this.chartData
          this.chartInstance.update()
        }
      },
    },
  }
</script>

<style scoped>
.line-chart-container {
  width: 100%;
  height: 100%;
  display: block;
}
</style>
