<template>
  <div class="email-cadence-editor">
    <b-field
      label="Email Cadence">
      <SingleSelect
        :options="typeOptions"
        v-model="cadenceType"
        :enable-search="false"
        :return-as-object="false"/>
    </b-field>
    <template v-if="requiresWeekday">
      <validation-provider
        v-slot="{ errors }"
        rules="required">
        <b-field
          :type="errors.length ? 'is-danger' : ''"
          :message="errors[0]"
          label="Day of Week">
          <SingleSelect
            :options="weekdayOptions"
            v-model="weekday"
            :enable-search="false"
            :return-as-object="false"/>
        </b-field>
      </validation-provider>
    </template>
    <b-field label="On Failure"
             v-if="vModel">
      <SingleSelect
        :options="failureResponseOptions"
        v-model="failureResponse"
        :enable-search="false"
        :return-as-object="false"/>
    </b-field>
  </div>
</template>

<script>

  import SingleSelect from '@/components/ui/singleselect'

  export default {
    name: 'EmailCadenceEditor',
    components: {
      SingleSelect,
    },
    props: {
      value: {
        type: Object,
        default: null,
      },
    },
    data () {
      return {
        failureResponseOptions: [
          {
            id: 'IGNORE',
            name: 'Do Nothing',
          },
          {
            id: 'ALERT',
            name: 'Alert Ingest',
          },
        ],
        typeOptions: [
          {
            id: 'NONE',
            name: 'Unscheduled',
          },
          {
            id: 'MANAGED',
            name: 'Managed by Ingest',
          },
          {
            id: 'DAILY',
            name: 'Daily',
          },
          {
            id: 'WEEKLY',
            name: 'Weekly',
          },
        ],
        weekdayOptions: [
          {
            id: 0,
            name: 'Monday',
          },
          {
            id: 1,
            name: 'Tuesday',
          },
          {
            id: 2,
            name: 'Wednesday',
          },
          {
            id: 3,
            name: 'Thursday',
          },
          {
            id: 4,
            name: 'Friday',
          },
          {
            id: 5,
            name: 'Saturday',
          },
          {
            id: 6,
            name: 'Sunday',
          },
        ],
      }
    },
    computed: {
      requiresWeekday () {
        return this.cadenceType == 'WEEKLY'
      },
      cadenceType: {
        get () {
          if (!this.vModel) {
            return 'NONE'
          } else {
            return this.vModel.type
          }
        },
        set (cadenceType) {
          if (cadenceType == 'NONE') {
            this.vModel = null
          } else if (cadenceType == 'WEEKLY') {
            this.vModel = {
              type: cadenceType,
              weekday: 0,
            }
          } else {
            this.vModel = {
              type: cadenceType,
            }
          }
        },
      },
      failureResponse: {
        get () {
          return this.vModel.failureResponse || 'ALERT'
        },
        set (failureResponse) {
          this.vModel = {
            ...this.vModel,
            failureResponse,
          }
        },
      },
      weekday: {
        get () {
          if (this.requiresWeekday) {
            return this.vModel.weekday
          }
          return null
        },
        set (weekday) {
          this.vModel = {
            ...this.vModel,
            weekday,
          }
        },
      },
      vModel: {
        get () {
          return this.value
        },
        set (value) {
          this.$emit('input', value)
        },
      },
    },
  }

</script>

<style lang="scss" scoped>

</style>
