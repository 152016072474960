<template>
  <div
    class="table-column">
    <div
      class="table-column-header"
      :style="{ height: height + 'px' }">
      {{ column.label }}
    </div>

    <div
      v-if="hasSubColumns"
      class="table-column-sub-columns">
      <table-column
        v-for="subColumn in column.sub_columns"
        :key="subColumn.id"
        :current-depth="currentDepth + 1"
        :column="subColumn" />
    </div>

    <div v-else>
      <TableCell
        v-for="row, n in getRows()"
        :key="n"
        :row="row"
        :column="column" />
    </div>
  </div>
</template>

<script>
  import TableCell from './TableCell.vue'

  export default {
    name: 'TableColumn',
    components: {
      TableCell,
    },
    inject: ['getMaxDepth','getRows',],
    props: {
      column: {
        type: Object,
        required: true,
      },
      currentDepth: {
        type: Number,
        required: true,
      },

    },
    data () {
      return {
        headerCellHeight: 40,
      }
    },

    computed: {
      hasSubColumns () {
        return this.column.sub_columns.length > 0
      },

      height () {
        return this.headerCellHeight * (this.getMaxDepth() - this.column.depth - this.currentDepth + 1)
      },
    },

    mounted () {},
    beforeCreate: function () {
      this.$options.components.tableColumn = require('./TableColumn').default
    },
    methods: {},
  }
</script>

<style lang="scss" scoped>
.table-column-header {
  min-width: 200px;
  height: 50px;
  background-color: #f0f0f0;
  border: 1px solid #e0e0e0;
  padding: 10px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
}

.table-column-sub-columns {
  display: flex;
}


</style>
