<template>
  <div class="graph-controls">
    <div class="search-container">
      <b-field>
        <b-input
          v-model="searchQuery"
          type="search"
          icon="magnify"
          placeholder="Search"
          expanded
          class="no-border-input" />
      </b-field>
    </div>
    <div class="toggle-container">
      <span class="toggle-label">Show:</span>
      <div class="toggle-buttons">
        <button
          class="button is-small toggle-button"
          @click="showAll">
          All
        </button>
        <button
          class="button is-small toggle-button"
          @click="hideAll">
          None
        </button>
      </div>
      <button
        class="button is-small toggle-button ml-auto"
        @click="toggleSortOrder">
        <i class="material-icons-outlined">
          {{ sortAscending ? 'arrow_upward' : 'arrow_downward' }}
        </i>
      </button>
    </div>
    <div class="filter-container">
      <template v-if="isLoading">
        <div
          v-for="n in 5"
          :key="n"
          class="filter-item-skeleton">
          <b-skeleton
            width="100%"
            height="40px" />
        </div>
      </template>
      <template v-else-if="type === 'bar'">
        <div
          v-for="bar in searchResults"
          :key="bar.id"
          class="filter-item"
          @click="toggleItem(bar.id)">
          <div
            class="filter-item-content"
            :class="{ 'filter-item-active': bar.isActive }">
            <span
              class="color-circle"
              :style="{ backgroundColor: bar.color }" />
            <span class="filter-label">{{ bar.label }}</span>
            <i class="material-icons-outlined visibility-icon">
              {{ bar.isActive ? "visibility" : "visibility_off" }}
            </i>
            <span class="filter-value">{{ bar.values }}</span>
          </div>
        </div>
      </template>
      <template v-else-if="type === 'line'">
        <div
          v-for="line in searchResults"
          :key="line.id"
          class="filter-item"
          :class="{ 'filter-item-active': line.isActive }"
          @click="toggleItem(line.id)">
          <div
            class="filter-item-content"
            :class="{ 'filter-item-active': line.isActive }">
            <span
              class="color-circle"
              :style="{ backgroundColor: line.color }" />
            <span class="filter-label">{{ line.label }}</span>
            <i class="material-icons-outlined visibility-icon">
              {{ line.isActive ? "visibility" : "visibility_off" }}
            </i>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
  import FuzzySearch from 'fuzzy-search'

  export default {
    props: {
      value: {
        type: Array,
        default: () => [],
      },
      type: {
        type: String,
        required: true,
      },
      isLoading: {
        type: Boolean,
        required: true,
      },
    },
    data () {
      return {
        searchQuery: '',
        searcher: null,
        sortAscending: true,
      }
    },
    computed: {
      searchResults () {
        return this.searcher
          ? this.searcher.search(this.searchQuery)
          : this.value
      },
    },
    watch: {
      value: {
        handler () {
          this.updateSearcher()
        },
        immediate: true,
      },
    },
    methods: {
      toggleSortOrder () {
        this.sortAscending = !this.sortAscending
        const sortedItems = [...this.value,].sort((a, b) => {
          if (this.sortAscending) {
            return a.sort_by.toString().localeCompare(b.sort_by.toString())
          }
          return b.sort_by.toString().localeCompare(a.sort_by.toString())
        })

        this.$emit('input', sortedItems)
      },
      toggleItem (id) {
        const index = this.value.findIndex((item) => item.id === id)
        if (index !== -1) {
          const updatedItems = [...this.value,]
          updatedItems[index] = {
            ...updatedItems[index],
            isActive: !updatedItems[index].isActive,
          }
          this.$emit('input', updatedItems)
        }
      },
      showAll () {
        const updatedItems = this.value.map((item) => ({
          ...item,
          isActive: true,
        }))
        this.$emit('input', updatedItems)
      },
      hideAll () {
        const updatedItems = this.value.map((item) => ({
          ...item,
          isActive: false,
        }))
        this.$emit('input', updatedItems)
      },
      updateSearcher () {
        this.searcher = new FuzzySearch(this.value, ['label',], {
          caseSensitive: false,
        })
      },
    },
  }
</script>

<style scoped lang="scss">
.graph-controls {
  height: 100%;
  display: flex;
  flex-direction: column;
  border-left: 1px solid #e0e0e0;
  background-color: white;
}

.search-container {
  padding: 4px;
  border-bottom: 1px solid #e0e0e0;
}

.no-border-input {
  ::v-deep .input {
    border: none;
    box-shadow: none;
  }
}

.filter-container {
  flex-grow: 1;
  overflow-y: auto;
  max-height: 500px;
  overflow-y: auto;
}

.filter-item,
.filter-item-skeleton {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 14px 12px;
  border-bottom: 1px solid #f0f0f0;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #f5f5f5;
    .filter-item-content {
      opacity: 1;
      .visibility-icon {
        opacity: 1 !important;
      }
    }
  }
}

.filter-item-content {
  display: flex;
  align-items: center;
  opacity: 0.5;
  width: 100%;

  .visibility-icon {
    opacity: 1;
  }

  &.filter-item-active {
    opacity: 1;
    .visibility-icon {
      opacity: 0;
    }
  }
}

.color-circle {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-right: 8px;
}

.filter-label {
  font-size: 14px;
}

.filter-value {
  color: #666;
  font-size: 14px;
}

.toggle-container {
  display: flex;
  align-items: center;
  padding: 5px 12px;
  border-bottom: 1px solid #e0e0e0;
}

.toggle-label {
  font-size: 14px;
  color: #999;
  margin-right: 8px;
}

.toggle-buttons {
  display: flex;
  gap: 8px;
  border: none;
  margin-left: 12px;
}

.toggle-button {
  font-size: 12px;
  padding: 0px 8px;
  background-color: #fff;
  color: #888 !important;
  transition: all 0.3s ease;
  border: none !important;

  &:hover {
    background-color: #f0f0f0;
  }
}

.visibility-icon {
  margin-left: auto;
  margin-right: 8px;
  color: #666;
  font-size: 16px;
  opacity: 0.5;
}

.filter-item-skeleton {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
