<template>
  <div id="app">
    <div
      v-if="isLoading"
      class="loading-overlay">
      <!-- <lottie-player
        src="https://lottie.host/c82b688b-d3d2-463b-8ef7-16cb0b169ba3/FwKwjZGZTa.json"
        background="transparent"
        speed="1"
        style="width: 70px"
        autoplay
        loop /> -->
    </div>
    <transition name="fade">
      <router-view class="router-view" />
    </transition>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  export default {
    name: 'App',
    components: {},
    computed: {
      ...mapGetters({
        isLoading: 'isLoading',
      }),
    },
    mounted () {
      console.log('version:', '1.0.0')
      //todo: log the last deployed time via env variable

    },
  }
</script>

<style lang="scss">
html {
  font-family: "DM Sans", sans-serif;
}

i {
  font-family: Material Icons;
  font-style: normal;
}
#app,
body,
html {
  height: 100%;
}
</style>

<style lang="scss" scoped>
.loading-overlay {
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  background-color: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(5px);
}
</style>
