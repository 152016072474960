<template>
  <div
    :key="tabs.length"
    class="dashboard-tabs-container">
    <div class="dashboard-tabs is-hidden-mobile">
      <div
        v-for="(tab, i) in tabs"
        :key="i"
        :class="{ active: currentTab === tab.id }"
        class="tab"
        @click="selectTab(tab.id)">
        <div
          v-if="tab.icon"
          class="icon-container">
          <i class="material-icons">{{ tab.icon }}</i>
        </div>
        <div class="tab-label">
          {{ tab.label }}
        </div>
      </div>
    </div>
    <b-dropdown
      v-model="currentTab"
      class="mobile-dropdown is-hidden-tablet">
      <template #trigger>
        <div class="dropdown-trigger">
          <div
            v-if="getCurrentTabIcon()"
            class="icon-container">
            <i class="material-icons">{{ getCurrentTabIcon() }}</i>
          </div>
          <div class="tab-label">
            {{ getCurrentTabLabel() }}
          </div>

          <i class="mdi mdi-chevron-right" />
        </div>
      </template>

      <b-dropdown-item
        v-for="(tab, i) in tabs"
        :key="i"
        :value="tab.id">
        <div class="dropdown-item-content">
          <i
            v-if="tab.icon"
            class="material-icons">{{ tab.icon }}</i>
          <span>{{ tab.label }}</span>
        </div>
      </b-dropdown-item>
    </b-dropdown>
  </div>
</template>

<script>
  export default {
    props: {
      tabs: {
        type: Array,
        default: () => [],
      },

      value: {
        type: String,
        default: '',
      },
    },
    data () {
      return {}
    },
    computed: {
      currentTab: {
        get () {
          return this.value
        },
        set (value) {
          this.$emit('input', value)
        },
      },
    },

    methods: {
      selectTab (tab) {
        this.currentTab = tab
      },
      getCurrentTabLabel () {
        const currentTab = this.tabs.find((tab) => tab.id === this.currentTab)
        return currentTab ? currentTab.label : 'Select Tab'
      },
      getCurrentTabIcon () {
        const currentTab = this.tabs.find((tab) => tab.id === this.currentTab)
        return currentTab ? currentTab.icon : null
      },
    },
  }
</script>
<style lang="scss" scoped>
.dashboard-tabs {
  display: flex;
  width: 100%;
}

.dashboard-tabs-container {
  display: flex;
  width: 100%;
}
.tab {
  width: 100%;
  border-radius: 0;
  border-left: 0.5px solid #e0e0e0;
  border-right: 0.5px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  border-top: none;
  height: 48px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
  margin-bottom: 0 !important;

  &:hover {
    background-color: #f5f5f5;
  }
  &.active {
    background-color: #994095;
    color: white;

    .icon-container {
      border-color: #b654b1;
      i {
        color: white;
      }
    }
  }
}

.icon-container {
  display: flex;
  align-items: center;
  height: 48px;
  width: 36px;
  justify-content: center;
  border-right: 1px solid #f5f5f5;
  i {
    font-size: 18px;
    color: #d2d2d4;
  }
}

.tab-label {
  padding-left: 10px;
}

.mobile-dropdown {
  width: 100%;

  .dropdown-trigger {
    width: 100%;
    font-weight: 700;
    display: flex;
    align-items: center;
    padding: 0 10px;
    background-color: $primary;
    color: white;
    .tab-label {
      font-size: 16px;
      width: 100%;
      align-items: center;
    }

    i {
      font-size: 22px;
    }

    .icon-container {
      border-right: none !important;
    }
  }

  .dropdown-item-content {
    display: flex;
    align-items: center;

    i {
      margin-right: 10px;
    }
  }
}
</style>
