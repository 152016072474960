<template>
  <canvas
    ref="barChart"
    class="bar-chart-container" />
</template>

<script>
  import { Chart, registerables } from 'chart.js'
  Chart.register(...registerables)

  export default {
    name: 'BarChart',
    props: {
      data: {
        type: Array,
        required: true,
      },
      yAxisDataType: {
        type: String,
        required: true,
      },
      measureLabel : {
        type: String,
        default: '',
      },
      benchmarkLabel : {
        type: String,
        default: '',
      },
    },
    data () {
      return {
        chartInstance: null,
      }
    },
    computed: {
      chartData () {
        const labels = this.data.map(item => item.label)
        const datasets = [
          {
            label: this.measureLabel,
            data: this.data.map(item => item.value),
            backgroundColor: this.data.map(item => item.color),
            borderWidth: 0,
            borderRadius: {
              topLeft: 5,
              topRight: 5,
              bottomLeft: 0,
              bottomRight: 0,
            },
            borderSkipped: 'bottom',
            formatted_data: this.data.map(item => item.formatted_value),
          },
          {
            label: this.benchmarkLabel,
            data: this.data.map(item => item.benchmark),
            backgroundColor: this.data.map(item => item.color + '33'), // Adding 33 for 20% opacity
            borderWidth: 0,
            borderRadius: {
              topLeft: 5,
              topRight: 5,
              bottomLeft: 0,
              bottomRight: 0,
            },
            borderSkipped: 'bottom',
            formatted_data: this.data.map(item => item.formatted_benchmark),
          },
        ]
        return { labels, datasets, }
      },
    },
    watch: {
      data: {
        handler () {
          this.updateChart()
        },
        deep: true,
      },
    },
    mounted () {
      this.createChart()
      window.addEventListener('resize', this.resizeChart)
    },
    beforeUnmount () {
      if (this.chartInstance) {
        this.chartInstance.destroy()
      }
      window.removeEventListener('resize', this.resizeChart)
    },
    methods: {
      createChart () {
        const ctx = this.$refs.barChart.getContext('2d')
        this.chartInstance = new Chart(ctx, {
          type: 'bar',
          data: this.chartData,
          options: {
            responsive: true,
            maintainAspectRatio: false,
            scales: {
              x: {
                stacked: false,
                grid: {
                  display: false,
                },
              },
              y: {
                beginAtZero: true,
                ticks: {
                  callback: (value) => {
                    if (this.yAxisDataType === 'money') {
                      return '$' + (value / 100).toLocaleString()
                    }
                    if (this.yAxisDataType === 'percentage') {
                      return (value * 100).toFixed(2) + '%'
                    }
                    return value
                  },
                },
              },
            },
            plugins: {
              legend: {
                display: false,
              },
              tooltip: {
                mode: 'index',
                intersect: false,
                callbacks: {
                  title: function (tooltipItems) {
                    return tooltipItems[0].label
                  },
                  label: function (context) {
                    const datasetLabel = context.dataset.label || ''
                    const value = context.dataset.formatted_data[context.dataIndex]
                    return `${datasetLabel}: ${value}`
                  },
                },
              },
            },
            onClick: (event, elements) => {
              if (elements.length > 0) {
                const barIndex = elements[0].index
                const item = this.data[barIndex]
                this.$emit('onDrilldown', {
                  key: item.id,
                  label: item.label,
                })
              }
            },
          },
        })
      },
      updateChart () {
        if (this.chartInstance) {
          this.chartInstance.data = this.chartData
          this.chartInstance.update()
        }
      },
      resizeChart () {
        if (this.chartInstance) {
          this.chartInstance.resize()
        }
      },
    },
  }
</script>

<style scoped>
.bar-chart-container {
  width: 100%;
  height: 100%;
  display: block;
}
</style>
