<template>
  <div class="graph-module">
    <div class="graph-header">
      {{ dashboardModule.name || "Untitled" }}
      <div
        v-if="drilldownPath?.length"
        class="drilldown-path">
        <div class="drilldown-items">
          <div
            v-for="(item, index) in drilldownPath"
            :key="index"
            class="drilldown-item">
            <div class="drilldown-content">
              <div class="drilldown-value">
                {{ item.value.label }}
              </div>
            </div>
            <i
              v-if="index < drilldownPath.length - 1"
              class="mdi mdi-chevron-right" />
          </div>
        </div>
      </div>
      <div class="actions">
        <button
          class="button is-small toggle-button"
          @click="exportCsv">
          <i class="mdi mdi-download" />
        </button>

        <button
          class="button is-small toggle-button is-hidden-tablet"
          @click="isSettingsSidebarOpen = true">
          <i class="mdi mdi-cog" />
        </button>
      </div>
    </div>
    <div class="graph-body">
      <div class="graph-container">
        <template v-if="isLoading">
          <b-skeleton
            height="400px"
            width="100%"
            :animated="true" />
        </template>
        <template v-else>
          <BarChart
            v-if="type === 'bar'"
            :y-axis-data-type="yAxisDataType"
            :data="filteredChartItems"
            :measure-label="measureLabel"
            :benchmark-label="benchmarkLabel"
            @onDrilldown="handleDrilldown" />
          <LineChart
            v-if="type === 'line'"
            :x-axis-labels="xAxisLabels"
            :y-axis-data-type="yAxisDataType"
            :measure-label="measureLabel"
            :benchmark-label="benchmarkLabel"
            :data="filteredChartItems"
            @onDrilldown="handleDrilldown" />
        </template>
      </div>
      <GraphControls
        v-model="chartItems"
        class=" is-hidden-mobile"
        :is-loading="isLoading"
        style="width: 360px"
        :type="type" />
    </div>
    <b-sidebar
      v-model="isSettingsSidebarOpen"
      type="is-light"
      :fullheight="true"
      :fullwidth="false"
      :overlay="false"
      :right="true"
      :reduce="false"
      :open="isSettingsSidebarOpen"
      class="is-hidden-tablet">
      <GraphControls
        v-model="chartItems"
        :is-loading="isLoading"
        :type="type" />
    </b-sidebar>
  </div>
</template>

<script>
  import BarChart from './BarChart.vue'
  import LineChart from './LineChart.vue'
  import GraphControls from './GraphControls.vue'
  import axios from 'axios'

  const colors = {
    twilightBlue: '#438A9D',
    duskPurple: '#7F70CF',
    auroraGreen: '#67CD9C',
    duskRed: '#B85C81',
    duskOrange:'#F17C57',
    sunriseYellow: '#F6C856',
    middayblue: '#52AFC8',
    twilightPurple: '#8A88E9',
    dawnOrange: '#F69D4C',
    morningBlue: '#91D1E3',
    sunsetPine: '#D592E8',
  }

  export default {
    name: 'GraphModule',
    components: { BarChart, LineChart, GraphControls, },
    props: {
      type: {
        type: String,
        required: true,
      },
      dashboardModule: {
        type: Object,
        required: true,
      },
      dashboardDataContext: {
        type: Object,
        required: true,
      },
      dashboardKey: {
        type: String,
        required: true,
      },
    },
    data () {
      return {
        searchQuery: '',
        chartItems: [],
        isLoading: true,
        isExporting: false,
        isSettingsSidebarOpen: false,
        cancelTokenSource: null,

        xAxisLabels: [],
        yAxisDataType: null,
        benchmarkLabel: '',
        measureLabel: '',

        behaviors: {
          drilldown: {
            control: null,
          },
        },

      }
    },
    computed: {
      filteredChartItems () {
        return this.chartItems.filter((item) => item.isActive)
      },

      drilldownPath () {
        return this.dashboardDataContext?.control_selections?.[this.behaviors?.drilldown?.control]?.drilldown_path || []
      },
    },

    watch: {
      dashboardDataContext: {
        handler () {
          this.loadData()
        },
        deep: true,
      },
    },

    mounted () {
      // this.loadData()
    },

    beforeDestroy () {
      if (this.cancelTokenSource) {
        this.cancelTokenSource.cancel('Component destroyed')
      }
    },

    methods: {
      handleDrilldown (data) {
        this.$emit('onDrilldown', {
          ...data, control: this.behaviors?.drilldown?.control,
        })
      },
      async loadData () {
        try {
          // Cancel previous request if it exists
          if (this.cancelTokenSource) {
            this.cancelTokenSource.cancel('New request initiated')
          }

          // Create new CancelToken
          this.cancelTokenSource = axios.CancelToken.source()

          this.isLoading = true

          const response = await this.$apis.companyConfigs.getDashboardModuleData(
            this.dashboardKey,
            this.dashboardModule.key,
            this.dashboardDataContext,
            { cancelToken: this.cancelTokenSource.token, }
          )
          if (response?.error) {
            if (response?.code !=='ERR_CANCELED') {
              this.$buefy.toast.open({
                message: 'Failed to load Chart Data',
                type: 'is-warning',
              })
            }
            return
          }

          if (this.type === 'bar') {
            this.chartItems = response.bars.map((item, index) => ({
              ...item,
              color: Object.values(colors)[index % Object.keys(colors).length],
              isActive: item.isActive !== undefined ? item.isActive : true,
            }))

            this.yAxisDataType = response.y_axis_data_type
          } else if (this.type === 'line') {
            this.chartItems = response.lines.map((item, index) => ({
              ...item,
              color: Object.values(colors)[index % Object.keys(colors).length],
              isActive: item.isActive !== undefined ? item.isActive : true,
            }))
            this.yAxisDataType = response.y_axis_data_type
            this.xAxisLabels = response.x_axis_labels
          }
          this.benchmarkLabel =response.benchmark_label
          this.measureLabel = response.measure_label
          this.behaviors = response.behaviors

          this.isLoading = false
        } catch (error) {
          console.log(error)
        }
      },

      async exportCsv () {
        this.isExporting = true

        const response = await this.$apis.companyConfigs.getDashboardModuleCsv(
          this.dashboardKey,
          this.dashboardModule.key,
          this.dashboardDataContext
        )

        const blob = new Blob([response,], { type: 'text/csv', })
        const url = window.URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.style.display = 'none'
        a.href = url
        a.download = 'dashboard_data.csv'
        document.body.appendChild(a)
        a.click()
        window.URL.revokeObjectURL(url)
        document.body.removeChild(a)

        this.isExporting = false
      },
    },
  }
</script>

<style scoped lang="scss">

.graph-module {
  width: 100%;
  height: 100%;
  display: flex;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  overflow: hidden;
  flex-direction: column;
  background-color: white;
}
.graph-header {
  padding: 12px;
  border-bottom: 1px solid #e0e0e0;
  font-size: 16px;
  color: #333;
  padding: 8px;
  display: flex;
  align-items: center;

  .drilldown-path {
    margin-left: 12px;

    .drilldown-items {
      display: flex;
      flex-wrap: wrap;
      gap: 4px;
      align-items: center;
    }

    .drilldown-item {
      display: flex;
      align-items: center;
      background: #f5f5f5;
      padding: 4px 8px;
      border-radius: 4px;
      font-size: 12px;

      .drilldown-content {
        display: flex;
        gap: 4px;
      }

      .drilldown-option {
        color: #666;
        font-weight: 500;
      }

      .drilldown-value {
        color: #333;
      }

      .mdi-chevron-right {
        color: #999;
        margin: 0 4px;
      }
    }
  }

  .actions {
    margin-left: auto;

    .mdi-download {
      font-size: 18px;
    }
  }
}
.graph-body {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.graph-container {
  height: 100%;
  max-height: 600px;
  width: calc(100% - 360px);
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @include mobile {
    width: 100%;
    padding: 10px;
  }
}
</style>
