<template>
  <div class="other-filter">
    <div class="quick-actions">
      <div class="button-label">
        {{ filterMeta?.name }}
      </div>
    </div>
    <div class="dropdown-filter">
      <singleSelect
        v-model="selectionModel"
        track-by="key"
        :no-border="true"
        label="label"
        :disabled="loading"
        :loading="loading"
        :options="dropdownOptions"
        @input="updateSelectionModel">
        <template #before>
          <div
            v-if="drilldownValues?.length"
            class="drilldown-container">
            <h2>Drilldown</h2>

            <div class="drilldown-items">
              <div
                v-for="(item, index) in drilldownValues"
                :key="index"
                class="drilldown-item">
                <div class="drilldown-content">
                  <div class="drilldown-option">
                    {{ optionKeyLabelMap[item.option] }}:
                  </div>
                  <div class="drilldown-value">
                    {{ item.value.label }}
                  </div>
                </div>
                <button
                  class="remove-drilldown"
                  @click.prevent="removeDrilldown(index)">
                  <i class="material-icons">close</i>
                </button>
              </div>
            </div>
          </div>
        </template>
      </singleSelect>
    </div>
  </div>
</template>

<script>
  import singleSelect from '@/components/ui/singleselect'

  export default {
    name: 'SectionControlFilters',
    components: {
      singleSelect,
    },
    mixins: [],
    props: {
      filterMeta: {
        type: Object,
        required: true,
      },
      value: {
        type: Object,
        required: true,
      },
    },
    data () {
      return {
        selectionModel: null,
        loading: false,
        dropdownOptions: [],
      }
    },
    computed: {
      dataContext: {
        get () {
          return this.value
        },
        set (newValue) {
          this.$emit('input', newValue)
        },
      },
      drilldownValues () {
        return this.dataContext?.drilldown_path || null
      },
      optionKeyLabelMap () {
        return this.filterMeta.options?.reduce((acc, dv) => {
          acc[dv.key] = dv.label
          return acc
        }, {}) || {}
      },
    },
    watch: {
      'drilldownValues': {
        handler (newValue) {
          if (!newValue || newValue.length === 0) {
            this.dropdownOptions = this.filterMeta.options
            this.updateSelectionModel(this.dropdownOptions[0])
          }

          else {

            // Get array of keys that are in the drilldown path
            const drilldownKeys = newValue.map(dv => dv.option)
            // Filter options to exclude any that are in drilldown path
            this.dropdownOptions = this.filterMeta.options.filter(option => {
              return !drilldownKeys.includes(option.key)
            })

            // Update selection if options exist
            if (this.dropdownOptions.length > 0) {
              this.updateSelectionModel(this.dropdownOptions[0])
            }
          }
        },
        immediate: true,
      },

    },
    async mounted () {
      this.dropdownOptions = this.filterMeta.options
      this.updateSelectionModel(this.dropdownOptions[0])
    },

    methods: {
      updateSelectionModel (value) {
        this.selectionModel = value
        this.$set(
          this.dataContext,
          'selected_option',
          value.key
        )
      },
      removeDrilldown (index) {
        this.drilldownValues.splice(index, 1)
      },
    },
  }
</script>
<style lang="scss" scoped>
.other-filter {
  background-color: white;
  border-radius: 4px;
  width: 100%;
  border: 0.2px solid $gray-1;
}

.drilldown-container {
  padding: 8px;

  h2 {
    margin-bottom: 10px;
  }
}

.drilldown-items {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.drilldown-item {
  background: white;
  border: 1px solid $gray-1;
  border-radius: 4px;
  padding: 8px 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  width: 200px;
  flex: 0 0 200px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  .drilldown-content {
    flex: 1;
  }

  .drilldown-option {
    font-size: 12px;
    color: $grayscale-4;
    text-transform: uppercase;
    margin-bottom: 4px;
  }

  .drilldown-value {
    font-size: 14px;
    color: $grayscale-2;
    font-weight: 500;
  }

  .remove-drilldown {
    background: none;
    border: none;
    padding: 0;
    margin-left: 8px;
    cursor: pointer;
    color: $grayscale-4;
    display: flex;
    align-items: center;
    justify-content: center;

    i {
      font-size: 16px;
    }

    &:hover {
      color: $grayscale-2;
    }
  }
}

.filter-button {
  border: 1px solid $gray-1;
  display: flex;
  align-items: center;
  padding: 8px 15px;
  border-radius: 4px;
  width: 100%;
  cursor: pointer;

  &.filtered {
    border: 1px solid #ffb1a0;

    .icon {
      color: #ff6341 !important;
    }

    .button-value {
      color: #ff6341 !important;
    }
  }

  .icon {
    color: $gray-3;
    font-size: 18px !important;

    &.right {
      margin-left: auto;
      margin-right: 0;
    }
  }

  .button-content {
    margin: 0 15px;
    height: 28px;
    display: flex;
    align-items: center;
    .button-value {
      font-size: 14px;
      color: $grayscale-2;
      max-width: 200px;
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .loading-message {
      font-size: 13px;
      color: $grayscale-4;
    }
  }
  &:hover {
    box-shadow: $box-shadow-1;
  }
}

.dropdown-content {
  border-radius: 4px !important;
}

.quick-actions {
  display: flex;
  align-items: center;
  // border: 1px solid $gray-1;
  // border-bottom: none;
  border-bottom: 1px solid $gray-1;
  border-top: none;
  background-color: #fbfbfb;
  height: 30px;
  overflow: hidden;

  .button-label {
    font-size: 13px;
    margin-right: auto;
    color: #504f54;
    font-weight: 200;
    padding: 0 15px;
    border-right: 1px solid $gray-1;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .quick-action-button {
    height: 100%;
    font-size: 13px;
    color: #504f54;
    font-weight: 200;
  }

  .remove-button {
    border: none;
    color: #504f54;
    background-color: transparent;
    padding: 0;
    width: 32px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0;
    border-left: 1px solid $gray-1;

    outline: none;

    &:focus {
      outline: none;
    }

    .icon {
      font-size: 17px;
    }
  }
}
</style>
