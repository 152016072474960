var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"section-container"},[_c('div',{staticClass:"filters"},_vm._l((_vm.dashboardSection.controls),function(filter){return _c('div',{key:filter.key,staticClass:"filter"},[(_vm.sectionDataContext[filter.key])?_c('SectionControlFilters',{attrs:{"filter-meta":filter},model:{value:(_vm.sectionDataContext[filter.key]),callback:function ($$v) {_vm.$set(_vm.sectionDataContext, filter.key, $$v)},expression:"sectionDataContext[filter.key]"}}):_vm._e()],1)}),0),_vm._l((_vm.dashboardSection?.sub_sections),function(section){return _c('section',{key:section.id},[(section.name)?_c('h2',[_vm._v(" "+_vm._s(section.name)+" ")]):_vm._e(),_c('div',{staticClass:"columns is-multiline p-0 m-0"},[_vm._l((section?.modules),function(mod){return [_c('div',{key:mod?.id,class:[
            'column p-0 m-0',
            {
              // 'is-full': true,
              'is-full': ['SnapshotTable', 'CategoryLineChart', 'PerformanceBarChart'].includes(mod.type),
              'is-4': mod.type === 'KpiModuleConfig',
            },
          ]},[(mod.type === 'Kpi')?_c('kpiModule',{attrs:{"dashboard-module":mod,"dashboard-data-context":_vm.mergedDataContext,"dashboard-key":_vm.dashboardKey}}):_vm._e(),(mod.type === 'SnapshotTable')?_c('tableModule',{attrs:{"dashboard-module":mod,"dashboard-data-context":_vm.mergedDataContext,"dashboard-key":_vm.dashboardKey}}):_vm._e(),(['CategoryLineChart', 'PerformanceBarChart'].includes(mod.type))?_c('graphModule',{attrs:{"dashboard-module":mod,"dashboard-data-context":_vm.mergedDataContext,"dashboard-key":_vm.dashboardKey,"type":mod.type === 'PerformanceBarChart' ? 'bar' : 'line'},on:{"onDrilldown":_vm.handleDrilldown}}):_vm._e()],1)]})],2)])})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }