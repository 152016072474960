const tableRowObject = () => {
  return {
    name: '',
    dimension: {
      type: 'CompanyDimensionRef',
      key: 'location',
    },
    order: null,
    table_totals: [
      {
        name: 'Total',
        data_format: null,
        time_range: null,
        offset_days: null,
        is_pct_total: null,
        pct_total_dimensions: null,
        filters: [],
        comp_filter_type: 'none',
        header_style: {
          text_style: null,
          text_color: null,
          h_align: null,
          v_align: null,
          border_radius: null,
          background_color: null,
          pad_left: null,
          pad_right: null,
          pad_top: null,
          pad_bottom: null,
        },
        data_style: {
          text_style: null,
          text_color: null,
          h_align: null,
          v_align: null,
          border_radius: null,
          background_color: null,
          pad_left: null,
          pad_right: null,
          pad_top: null,
          pad_bottom: null,
        },
      },
    ],
    filters: [],
    data_format: {
      data_type: 'label',
      show_commas: true,
      decimal_places: 2,
      parenthesize_negatives: false,
      mm_ss: false,
    },
    header_style: {
      text_style: null,
      text_color: null,
      h_align: null,
      v_align: null,
      border_radius: null,
      background_color: null,
      pad_left: null,
      pad_right: null,
      pad_top: null,
      pad_bottom: null,
    },
    data_style: {
      text_style: null,
      text_color: null,
      h_align: null,
      v_align: null,
      border_radius: null,
      background_color: null,
      pad_left: null,
      pad_right: null,
      pad_top: null,
      pad_bottom: null,
    },
    column_width: 260,
  }
}

const pivotGroupObject = () => {
  return {
    name: '',
    measure_groups: [],
    table_measures: [],
    table_pivot: {
      name: null,
      dimension: {
        type: 'CompanyDimensionRef',
        key: 'meal_period',
      },
      table_totals: [],
      order: null,
      filters: [],
      data_format: {
        data_type: 'label',
        show_commas: true,
        decimal_places: 2,
        parenthesize_negatives: false,
        mm_ss: false,
      },
    },
    header_style: {
      text_style: null,
      text_color: null,
      h_align: null,
      v_align: null,
      border_radius: null,
      background_color: null,
      pad_left: null,
      pad_right: null,
      pad_top: null,
      pad_bottom: null,
    },
  }
}
const measureObject = () => {
  return {
    name: 'New Measure',
    measure_config: {
      measure: null,
      future_measure: null,
      time_range: null,
      offset_days: 0,
      repeat_config: null,
      is_pct_total: false,
      pct_total_dimensions: [],
      filters: [],
      comp_filter_type: 'none',
      avg_by_dimension: null,
    },
    comparison_config: null,
    data_format: {
      data_type: 'money',
      show_commas: true,
      decimal_places: 2,
      parenthesize_negatives: false,
      mm_ss: false,
    },
    header_style: {
      text_style: null,
      text_color: null,
      h_align: null,
      v_align: null,
      border_radius: null,
      background_color: null,
      pad_left: null,
      pad_right: null,
      pad_top: null,
      pad_bottom: null,
    },
    data_style: {
      text_style: null,
      text_color: null,
      h_align: null,
      v_align: null,
      border_radius: null,
      background_color: null,
      pad_left: null,
      pad_right: null,
      pad_top: null,
      pad_bottom: null,
    },
    column_width: 160,
  }
}
const totalRowObject = () => {
  return {
    name: 'Total',
    data_format: null,
    time_range: null,
    offset_days: null,
    is_pct_total: null,
    pct_total_dimensions: null,
    filters: [],
    comp_filter_type: 'none',
    header_style: {
      text_style: null,
      text_color: null,
      h_align: null,
      v_align: null,
      border_radius: null,
      background_color: null,
      pad_left: null,
      pad_right: null,
      pad_top: null,
      pad_bottom: null,
    },
    data_style: {
      text_style: null,
      text_color: null,
      h_align: null,
      v_align: null,
      border_radius: null,
      background_color: null,
      pad_left: null,
      pad_right: null,
      pad_top: null,
      pad_bottom: null,
    },
  }
}

const tableTotalObject = () => {
  return {
    name: 'Total',
    data_format: null,
    time_range: null,
    offset_days: null,
    is_pct_total: null,
    pct_total_dimensions: null,
    filters: [],
    comp_filter_type: 'none',
    header_style: {
      text_style: null,
      text_color: null,
      h_align: null,
      v_align: null,
      border_radius: null,
      background_color: null,
      pad_left: null,
      pad_right: null,
      pad_top: null,
      pad_bottom: null,
    },
    data_style: {
      text_style: null,
      text_color: null,
      h_align: null,
      v_align: null,
      border_radius: null,
      background_color: null,
      pad_left: null,
      pad_right: null,
      pad_top: null,
      pad_bottom: null,
    },
  }
}

const measureGroupObject = () => {
  return {
    name: '',
    measure_groups: [],
    table_measures: [],
    table_pivot: null,
    header_style: {
      text_style: null,
      text_color: null,
      h_align: null,
      v_align: null,
      border_radius: null,
      background_color: null,
      pad_left: null,
      pad_right: null,
      pad_top: null,
      pad_bottom: null,
    },
  }
}

export {
  tableRowObject,
  pivotGroupObject,
  measureGroupObject,
  measureObject,
  tableTotalObject,
  totalRowObject
}
