<template>
  <div class="section-container">
    <div class="filters">
      <div
        v-for="filter in dashboardSection.controls"
        :key="filter.key"
        class="filter">
        <SectionControlFilters
          v-if="sectionDataContext[filter.key]"
          v-model="sectionDataContext[filter.key]"
          :filter-meta="filter" />
      </div>
    </div>

    <section
      v-for="section in dashboardSection?.sub_sections"
      :key="section.id">
      <h2 v-if="section.name">
        {{ section.name }}
      </h2>

      <div class="columns is-multiline p-0 m-0">
        <template v-for="mod in section?.modules">
          <div
            :key="mod?.id"
            :class="[
              'column p-0 m-0',
              {
                // 'is-full': true,
                'is-full': ['SnapshotTable', 'CategoryLineChart', 'PerformanceBarChart'].includes(mod.type),
                'is-4': mod.type === 'KpiModuleConfig',
              },
            ]">
            <!-- <tableModule
                :dashboard-module="mod"
                :dashboard-data-context="mergedDataContext"
                :dashboard-key="dashboardKey" /> -->
            <kpiModule
              v-if="mod.type === 'Kpi'"
              :dashboard-module="mod"
              :dashboard-data-context="mergedDataContext"
              :dashboard-key="dashboardKey" />

            <tableModule
              v-if="mod.type === 'SnapshotTable'"
              :dashboard-module="mod"
              :dashboard-data-context="mergedDataContext"
              :dashboard-key="dashboardKey" />

            <graphModule
              v-if="['CategoryLineChart', 'PerformanceBarChart'].includes(mod.type)"
              :dashboard-module="mod"
              :dashboard-data-context="mergedDataContext"
              :dashboard-key="dashboardKey"
              :type="mod.type === 'PerformanceBarChart' ? 'bar' : 'line'"
              @onDrilldown="handleDrilldown" />
          </div>
        </template>
      </div>
    </section>
  </div>
</template>
<script>
  import kpiModule from './modules/kpi/KPIModule.vue'
  import tableModule from './modules/table/TableModule.vue'
  import SectionControlFilters from './filters/SectionControlFilters.vue'
  import graphModule from './modules/graphs/GraphModule.vue'
  export default {
    components: {
      kpiModule,
      tableModule,
      SectionControlFilters,
      graphModule,
    },
    props: {
      dashboardSection: {
        type: Object,
        required: true,
      },
      dashboardDataContext: {
        type: Object,
        required: true,
      },
      dashboardKey: {
        type: String,
        required: true,
      },
    },
    data () {
      return {
        sectionDataContext: { },
      }
    },

    computed: {
      mergedDataContext () {
        const newDataContext = { ...this.dashboardDataContext, }
        newDataContext.control_selections = {
          ...this.dashboardDataContext.control_selections,
          ...this.sectionDataContext,
        }
        return newDataContext
      },
    },

    mounted () {
      this.dashboardSection.controls.forEach(control => {
        this.$set(this.sectionDataContext, control.key, {
          type: control.type,
          selected_option: null,
          drilldown_path : [],
        })
      })
    },

    methods: {
      handleDrilldown (drilldownData) {
        const control = drilldownData.control
        if (!control) return

        const filterOptionsLength = this.dashboardSection.controls.find(c => c.key === control)?.options?.length
        // Check if drilldown count equals option count
        if (filterOptionsLength - (this.sectionDataContext[control].drilldown_path?.length || 0) <= 1) {
          return
        }

        if ( !this.sectionDataContext[control].drilldown_path) {
          this.sectionDataContext[control].drilldown_path = []
        }

        this.sectionDataContext[control].drilldown_path.push({
          option: this.sectionDataContext[control].selected_option,
          value: {
            key: drilldownData.key,
            label: drilldownData.label,
          },
        })
      },
    },
  }
</script>
<style scoped lang="scss">
section {
  border-bottom: 1px solid #d2d2d4;
  // padding: 20px 16px;
  // padding-bottom: 32px;
  &:last-child {
    border-bottom: none;
  }
  @include mobile {
    padding: 0 10px;
  }
}

.section-container {
  h2 {
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 16px;
    color: #7e7d84;
  }
}

.filters {
  display: flex;
  flex-wrap: wrap;
  // border-bottom: 1px solid #d2d2d4;

  @include mobile {
    border-bottom: none;
    padding: 10px;
  }

  .filter {
    flex: 1;

    @include mobile {
      flex-basis: 100%;
      margin-bottom: 10px;
    }
  }
}
</style>
