export default {
  props: {
    value: {
      type: Object | Array,
      default: null,
    },
    context: {
      type: Object,
      default: Object,
    },
  },
  data () {
    return {
      defaultValue: {},
      dataTypes: [
        'money',
        'percentage',
        'number',
        'label',
        'id',
        'boolean',
        'date',
        'time',
        'datetime',
        'duration',
      ],
      timeRangeOptions: [
        {
          type: 'CalendarPreset',
          name: 'Current Day',
          key: 'current_day',
          include_today: false,
        },
        {
          type: 'CalendarPreset',
          name: 'Previous Day',
          key: 'previous_day',
          include_today: false,
        },
        {
          type: 'CalendarPreset',
          name: 'Current Week',
          key: 'current_week',
          include_today: false,
        },
        {
          type: 'CalendarPreset',
          name: 'Week to Date',
          key: 'wtd',
          include_today: false,
        },
        {
          type: 'CalendarPreset',
          name: 'Past 7 Days',
          key: 'trailing_7',
          include_today: false,
        },
        {
          type: 'CalendarPreset',
          name: 'Current Month',
          key: 'current_month',
          include_today: false,
        },
        {
          type: 'CalendarPreset',
          name: 'Month To Date',
          key: 'mtd',
          include_today: false,
        },
        {
          type: 'CalendarPreset',
          name: 'Current Period',
          key: 'current_period',
          include_today: false,
        },
        {
          type: 'CalendarPreset',
          name: 'Period To Date',
          key: 'ptd',
          include_today: false,
        },
        {
          type: 'CalendarPreset',
          name: 'Current Quarter',
          key: 'current_quarter',
          include_today: false,
        },
        {
          type: 'CalendarPreset',
          name: 'Quarter to Date',
          key: 'qtd',
          include_today: false,
        },
        {
          type: 'CalendarPreset',
          name: 'Current Year',
          key: 'current_year',
          include_today: false,
        },
        {
          type: 'CalendarPreset',
          name: 'Year to Date',
          key: 'ytd',
          include_today: false,
        },
        {
          type: 'CalendarPreset',
          name: 'All Time',
          key: 'all_time',
          include_today: false,
        },
      ],
    }
  },
  computed: {
    errors () {
      return this.vModel.errors || []
    },
    inputs: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      },
    },
  },
  mounted () {
    if (!this.inputs) {
      this.inputs = this.defaultValue
    }
  },
}
