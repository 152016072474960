<template>
  <div class="dashboard-report">
    <b-loading
      v-model="isLoading"
      :is-full-page="false">
      <!-- <lottie-player
        src="https://lottie.host/c82b688b-d3d2-463b-8ef7-16cb0b169ba3/FwKwjZGZTa.json"
        background="transparent"
        speed="1"
        style="width: 70px"
        autoplay
        loop /> -->
    </b-loading>
    <template v-if="!isLoading">
      <br />
      Dashboard Details: {{ dashboardDetails }}
      <br />
      <div class="optional-filters">
        Required Filters:
        <DashboardFilter v-for="filter in dashboardDetails.required_filters"
                         :key="filter.id"
                         :dashboard-data-context="fakeDataContext"
                         :dashboard-key="dashboardKey"
                         :dashboard-filter="filter" />
      </div>
      <div class="optional-filters">
        Optional Filters:
        <DashboardFilter v-for="filter in dashboardDetails.optional_filters"
                         :key="filter.id"
                         :dashboard-data-context="fakeDataContext"
                         :dashboard-key="dashboardKey"
                         :dashboard-filter="filter" />
      </div>
      <div class="dashboard-controls">
        Dashboard Controls:
        <DashboardControl v-for="control in dashboardDetails.dashboard_controls"
                          :key="control.id"
                          :dashboard-control="control" />
      </div>
      <div class="dashboard-sections">
        Dashboard Sections:
        <DashboardSection v-for="section in dashboardDetails.dashboard_sections"
                          :key="section.id"
                          :dashboard-data-context="fakeDataContext"
                          :dashboard-key="dashboardKey"
                          :dashboard-section="section" />
      </div>
    </template>
  </div>
</template>

<script>

  import DashboardFilter from '@/components/reports/dashboards/filters/DashboardFilter'
  import DashboardControl from '@/components/reports/dashboards/controls/DashboardControl'
  import DashboardSection from '@/components/reports/dashboards/sections/DashboardSection'

  export default {
    name: 'DashboardReport',
    components: {
      DashboardFilter,
      DashboardControl,
      DashboardSection,
    },
    props: {
      dashboardKey: {
        type: String,
        required: true,
      },
    },
    data () {
      return {
        isLoading: false,
        dashboardDetails: null,
        fakeDataContext: {
          date_selection: {
            selected_dates: [
              '2024-01-01',
              '2024-01-02',
              '2024-01-03',
              '2024-01-04',
              '2024-01-05',
              '2024-01-06',
              '2024-01-07',
              '2024-01-08',
            ],
          },
          filter_selections: {
            // revenue_center_filter: [
            //   '1',
            //   '7',
            //   '3',
            //   '67',
            // ],
          },
          control_selections: {
            // test_dimension_select: 'revenue_center',
          },
        },
      }
    },
    methods: {
      async loadDashboard (dashboardKey) {
        this.isLoading = true
        try {
          const dashboardDetails = await this.$apis.companyConfigs.getDashboardReportDetails(dashboardKey)
          if (dashboardKey === this.dashboardKey) {
            this.dashboardDetails = dashboardDetails
          }
        } finally {
          this.isLoading = false
        }
      },
    },
    mounted () {
      this.loadDashboard(this.dashboardKey)
    },
  }

</script>

<style lang="scss" scoped>

.dashboard-report {
  display: flex;
  flex-direction: column;
}

.optional-filters {
  display: flex;
  flex-direction: column;
}

.dashboard-controls {
  display: flex;
  flex-direction: column;
}

.dashboard-sections {
  display: flex;
  flex-direction: column;
}

</style>
