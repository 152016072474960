<template>
  <div>
    <b-select
      v-model="inputs"
      expanded
      aria-role="list">
      <option
        v-if="optional"
        :key="null"
        :value="null">
        Use report default
      </option>
      <option
        v-for="option in timeRangeOptions"
        :key="option.key"
        :value="option"
        aria-role="listitem">
        {{ option.name }}
      </option>
      <option
        :key="'relative_date_range'"
        value="relative_date_range">
        Relative Date Range
      </option>
    </b-select>
    <template v-if="inputs == 'relative_date_range'">
      <b-field
        label="Offset (days)"
        class="field">
        <b-input
          v-model="offsetDays" />
      </b-field>
      <b-field
        label="Duration (days)"
        class="field">
        <b-input
          v-model="durationDays" />
      </b-field>
    </template>
  </div>
</template>

<script>

  import ModuleConfigMixin from '@/components/reports/config/modules/common/ModuleConfigMixin'

  export default {
    name: 'TimeRangeEditor',
    mixins: [
      ModuleConfigMixin,
    ],
    props: {
      optional: {
        type: Boolean,
        default: true,
      },
    },
    data () {
      return {
        defaultValue: null,
      }
    },
    computed: {
      durationDays: {
        get () {
          return this.value.duration_days
        },
        set (duration_days) {
          this.$emit('input', {
            ...this.value,
            duration_days,
          })
        },
      },
      offsetDays: {
        get () {
          return this.value.offset_days
        },
        set (offset_days) {
          this.$emit('input', {
            ...this.value,
            offset_days,
          })
        },
      },
      inputs: {
        get () {
          if (this.value.type == 'RelativeDateRange') {
            return 'relative_date_range'

          }
          return this.value
        },
        set (value) {
          if (value == 'relative_date_range') {
            this.$emit('input', {
              type: 'RelativeDateRange',
              offset_days: 0,
              duration_days: 0,
            })
          } else {
            this.$emit('input', value)
          }
        },
      },
    },
  }

</script>
